
import { environment } from '../environments/environment';

export const config = {
  didCompany: 'safetrack',
  didCompanyDocument: 'safetrack.breending',
  firebaseCollection: environment.firebaseCollection,
  oauth: {
    url: `${environment.apiAuth}`,
    urlApi: `${environment.apiAuth}/api/`,
    urlApiSafetrack: `${environment.apiSafetrack}/api/`,
    logout: '/auth/logout',
    remote: `${environment.apiAuth}`,
  },
  api: {
    url: `${environment.apiUrl}`,
    auth: 'auth/',
  },
  urlIp: 'https://api.ipify.org?format=json',
  user: {
    mainEndpoint: 'user/',
    password: 'update-password/',
    active: 'active/',
    resetPassword: 'reset-password/',
    userAll: 'user',
    status: 'status/',
    profile: 'profile/',
    business: 'business/',
  },
  role: {
    mainEndpoint: 'role/',
    roleAll: 'role',
    instance: 'instance/',
    business: 'business/',
  },
  userRole: {
    mainEndpoint: 'user-role/',
    roles: 'roles/',
  },
  menuRole: {
    mainEndpoint: 'menu-role/',
    menus: 'menus/',
  },
  menu: {
    mainEndpoint: 'menu/',
    menuAll: 'menu',
  },
  register: {
    mainEndpoint: 'user/',
    userPaginate: 'userPaginate/',
    username: 'username/',
    role: 'role/',
    groups: 'groups/',
    list: 'list/',
    status: 'status/',
    sendmail: 'validate/sendmail',
    keycloak: 'keycloak/',
    certificationsPurchased: 'certificationsPurchased',
    certificationsPurchasedAll: 'certificationsPurchased/all',
  },
  business: {
    mainEndpoint: 'business/',
  },
  businessReport: {
    mainEndpoint: 'business-report/',
  },
  businessInstance: {
    mainEndpoint: 'business-instance',
  },
  instance: {
    mainEndpoint: 'instance/',
    instanceAll: 'instance',
    status: 'status/',
  },
  product: {
    mainEndpoint: 'product/',
    validationMark: 'validation-mark',
    reportProducts: 'product-report/',
    productAll: 'product',
    status: 'status/',
    business: 'business/',
    relations: '?relations=',
    relationsBusiness: 'business',
    iceSku: 'iceSku',
    all: 'all/',
    allEndorse: 'all-endorse',
  },
  productDetail: {
    mainEndpoint: 'product-detail/',
    product: 'product/',
  },
  productPrinter: {
    mainEndpoint: 'product-printer/',
    business: 'business/',
  },
  productPrinterBusiness: {
    mainEndpoint: 'product-printer-business/',
    business: 'business/',
    productPrinter: 'product-printer/',
  },
  productBusinessIntelligence: {
    mainEndpoint: 'business-intelligence/',
    business: 'business/',
    filter: 'filter',
  },
  purchaseOrder: {
    mainEndpoint: 'purcharse-order/',
    directEndpoint: 'purcharse-order',
    productAll: 'purcharse-order',
    salesOrder: 'sales-order/',
    stock: 'sismode/',
    status: 'status/',
    business: 'business/',
    relations: '?relations=',
    relationsBusiness: 'business',
    validation: 'validation/',
    validationPay: 'vaidation-pay/',
    validationDai: 'validation-dai/',
    validationEndpoint: 'purchase-order/',
    invalidateDocument: 'invalidate-document/',
    cancelOrder: 'anulate-order/',
    uploadDocument: 'upload-document/',
  },
  purchaseOrderDetailPrint: {
    mainEndpoint: 'purcharse-order-detail-print/',
    purchaseOrder: 'purcharse-order/',
  },
  uniqueMark: {
    mainEndpoint: 'unique-mark/',
    activate: 'activate/',
    individual: 'individual/',
    uniqueMarkImportLimit: 'unique-mark-import-limit/',
    resume: 'resume/',
    range: 'range/',
    validateActive: 'validate-activate/',
    validateDisable: 'validate-disable/',
    batch: 'batch/',
    disable: 'disable/',
    lotsUniqueMark: 'lots-unique-mark/',
    filter: 'filter/',
    consolidate: 'consolidate/',
    report: 'report/',
    aggregation: 'aggregation/',
  },
  uniqueMarkReport: {
    mainEndpoint: 'unique-mark-report/',
  },
  uniqueMarkPublic: {
    mainEndpoint: 'unique-mark-public/',
    count: 'count/',
  },
  lotsUniqueMark: {
    mainEndpoint: 'lots-unique-mark/',
    resume: 'resume/',
  },
  lotsuniqueMark: {
    mainEndpoint: 'lots-unique-mark/',
    resume: 'resume/',
  },
  // bio endpoints

  identity: {
    mainEndpoint: 'identity/',
    identityAll: 'identity',
    status: 'status/',
  },
  process: {
    process: 'process',
    processData: 'process/data',
    usedStock: 'process/data/used-stock',
    rolesProcess: 'roles-process/rol',
    processTemplate: 'process-template',
  },
  provider: {
    mainEndpoint: 'provider/',
    providerAll: 'all/',
  },
  client: {
    mainEndpoint: 'client/',
    clientAll: 'all/',
  },
  safetrack: {
    safetrack: 'safetrack/',
    identity: 'identity/',
    data: 'data/',
    transfer: 'transfer/',
    history: 'history/',
  },
  catalog: {
    mainEndpoint: 'catalog/',
    catalogDetail: 'catalog-detail',
    active: 'active/',
    status: 'status',
  },
  catalogDetail: {
    mainEndpoint: 'catalog-detail/',
    validation: 'validation/',
    catalog: 'catalog/',
    ordered: 'ordered/',
    name: 'name',
    description: 'description',
    taxesDocument: 'DOCUMENTO DE PAGO DE ADUANA',
    payDocument: 'TRANSFERENCIA PAGO',
    invoiceBDO: 'Factura BDO',
    sanitaryDocument: 'Registro Sanitario',
  },
  endorse: {
    mainEndpoint: 'endorse/',
  },
  pricePolicies: {
    mainEndpoint: 'price-policies/',
  },
  directions: {
    mainEndpoint: 'directions/',
    business: 'business/',
  },
  document: {
    mainEndpoint: 'document/',
    download: 'download/',
    catalogDetail: 'catalogDetail/',
    documentTypeFilter: 'sourceDocumentType',
  },
  shipmentFtp: {
    mainEndpoint: 'sri-shipment/',
    sriShipment: 'sri-shipment',
    mainEndpointSendFile: 'xml-report/',
    numberSendFile: 'number-send/',
    shipmentFtpAll: 'sri-shipment',
    signatureShipmentFtp: 'firmar/',
    downloadFileShipment: 'download-file/',
    shipmentToFtp: 'ftp/',
    shipmentDetail: 'details/',
  },
  reportClient: {
    clientReport: 'client-report/',
    clearTable: 'clear-tabla/',
    fillData: 'fill-data/',
  },
  productReport: {
    mainEndpoint: 'product/',
    all: 'all/',
    business: 'business/',
  },


};
