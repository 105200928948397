<div class="validator-button">
  <button type="submit" routerLink="./label-validator" nbButton hero fullWidth status="primary" size="small" shape="round">Valida tu etiqueta</button>
</div>
<div id="logotipo">
  <img src="./../../../assets/images/logo-safetrack.png" alt="Safetrack" width="130px" class="center">
</div>
<br>
<h1 id="title" class="title">Iniciar Sesión</h1>
<form [formGroup]="formData" (ngSubmit)="login()" aria-labelledby="title">
  <div class="form-control-group">
    <label class="label" for="input-username">Usuario <span class="text-danger">*</span></label>
    <input pInputText formControlName="username" placeholder="Ingrese el nombre de usuario" id="input-username"
      style="width: 100%">
    <div *ngIf="formData.controls['username'].invalid && (formData.controls['username'].dirty ||
      formData.controls['username'].touched)" class="text-danger">
      <div *ngIf="formData.controls['username'].errors.required">
        El usuario es obligatorio.
      </div>
      <div *ngIf="formData.controls['username'].errors.maxlength">
        Maximo de caracteres
      </div>
    </div>
  </div>

  <div class="form-control-group">
    <span class="label-with-link">
      <label class="label" for="input-password">Contraseña <span class="text-danger">*</span></label>
    </span>
    <div class="input-group">
      <!-- Change input type to be able to accept dynamic data -->
      <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" formControlName="password"
        id='input-password' placeholder="Ingrese su contraseña" pInputText />
      <div class="input-group-append">
        <span class="input-group-text" style="border: solid 1px #a6a6a6;">
          <i class="fa" [ngClass]="{
        'fa-eye-slash': !fieldTextType,
        'fa-eye': fieldTextType
      }" (click)="toggleFieldTextType()"></i>
        </span>
      </div>
    </div>
    <div *ngIf="
        formData.controls['password'].invalid && (formData.controls['password'].dirty ||
          formData.controls['password'].touched)" class="text-danger">
      <div *ngIf="formData.controls['password'].errors.required">
        La contraseña es obligatoria.
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
      </div>
      <div class="col-md-4">
        <span class="label-with-link float-right">
          <a class="forgot-password caption-2" routerLink="./reset-password">¿Olvidó su contraseña?</a>
        </span>
      </div>
    </div>
  </div>
  <button type="submit" nbButton fullWidth status="primary" shape="round">Ingresar</button>
  <div class="row">
    <div class="col-md-8">
    </div>
    <div class="col-md-4" style="margin-top: 0.3rem; align-items: right;">
      <span class="label-with-link float-right">
        <a class="forgot-password caption-2" routerLink="./label-count">Contador de etiquetas</a>
      </span>
    </div>
  </div>
</form>
<div class="d-flex justify-content-center pt-5">
  <div>
    <b>Powered by <a href="http://www.zeyo.io/" target="_blank"><img src="./../../../assets/images/Zeyo_logo.png" style="width: 80px;" class="logo" /></a></b>
  </div>
</div>