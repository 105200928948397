<nav class="mt-1" *ngIf="pages.length > 0">
    <ul class="row justify-content-md-center">
        <li class="page-item btn-disabled" (click)="firstPage()">
            <a class="page-link border-0">
                <em class="pi pi-angle-double-left"></em>
            </a>
        </li>
        <li class="page-item btn-disabled" (click)="previousPage()">
            <a class="page-link border-0">
                <em class="pi pi-angle-left"></em>
            </a>
        </li>
        <li id="pagina{{page.name}}" class="page-item btn-disabled" style="overflow-x: hidden;" *ngFor="let page of pages;"
            (click)="goToPage(page.name + 1)"><a class="page-link border-0">{{page.name +
                1}}</a></li>
        <li class="page-item btn-disabled" (click)="nextPage()">
            <a class="page-link border-0" >
                <em class="pi pi-angle-right"></em>
            </a>
        </li>
        <li class="page-item btn-disabled" (click)="lastPage()">
            <a class="page-link border-0" >
                <em class="pi pi-angle-double-right"></em>
            </a>
        </li>
    </ul>
</nav>


