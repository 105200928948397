<div class="4">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Orden {{value}}</h5>
            <div class="d-flex justify-content-between">
                <h6 class="textSubtitle mb-2 text-muted">Empresa</h6>
                <h6 class="textSubtitle mb-2 text-muted">Estado de compra</h6>
            </div>
            <div class="d-flex justify-content-between">
                <div class="pt-1">
                    <p class="card-text mb-2 text-muted">{{this.order.business.name | slice:0:25}}</p>
                </div>
                <div class="pt-1 pb-0 px-2" style="border: 1px solid #dee2e6;" [ngStyle]="{'border-color': this.order.status.name == 'GENERADO' ? 'orange': this.order.status.name == 'FACTURADO' ? '#85bb65': this.order.status.name == 'ANULADO' ? 'gray': 'blue'}">
                    <p class="card-text mb-0" style="font-size: 9px; font-weight: 500;" [ngStyle]="{'color': this.order.status.name == 'GENERADO' ? 'orange': this.order.status.name == 'FACTURADO' ? '#85bb65': this.order.status.name == 'ANULADO' ? 'gray ': 'blue'}">{{this.order.status.name}}</p>
                </div>
            </div>
            <hr class="px-2">
            <div class="d-flex justify-content-end">
                <button type="button" [routerLink]="['../',this.order.id,'view']"
                    class="btn btn-light px-2 py-1 mx-1 border titles list-buttons mb-1" title="Ver detalles de la orden de compra">
                    <em class="pi pi-align-justify font-weight-bold" style="font-size: 13px;"></em>
                </button>
                <!-- Admin Options -->
                
                <!-- Upload invoced -->
                <button *ngIf="this.optionsAdmin && this.order.status.code == '1'" (click)="uploadInvoice()" type="button"
                    class="btn btn-light px-2 py-1 mx-1 border titles list-buttons mb-1" title="Subir factura del cliente">
                    <em class="pi pi-upload font-weight-bold" style="font-size: 13px;"></em>
                </button>
                
                <!-- Validate pay document -->
                <button *ngIf="this.optionsAdmin && this.order.documentPayId && !this.order.validPay && this.order.status.code == '3'"
                    (click)="openModalValidatePay()" type="button" title="Validar documento de pago de la orden"
                    class="btn btn-outline-info px-2 py-1 mx-1 mb-1">
                    <em class="pi pi-check-square font-weight-bold" style="font-size: 13px;"></em>
                </button>
                
                <!-- Send order to sismode -->
                <button *ngIf="this.optionsAdmin && this.order.status.code == '4'" type="button" data-toggle="modal"
                    data-target="#confirmationValidModalSismode" class="btn btn-light px-2 py-1 mx-1 border titles list-buttons mb-1"
                    title="Enviar pedidos a sismode" (click)="uploadSismode()">
                    <em class="pi pi-cloud-upload font-weight-bold" style="font-size: 13px;"></em>
                </button>
                
                <!-- Validate tax document -->
                <button *ngIf="this.optionsAdmin && !this.order.validTax && this.order.business.businessType == 1"
                    (click)="openModalValidateDai()" type="button" title="Validar documento de impuesto"
                    class="btn btn-outline-warning px-2 py-1 mx-1 mb-1">
                    <em class="pi pi-check-circle font-weight-bold" style="font-size: 13px;"></em>
                </button>

                <!-- Descargar comprobante de Pago --> 
                <button *ngIf="this.optionsAdmin && this.order.documentPayId && this.order.validPay  && this.order.status.code != '99'"
                    (click)="downloadValidPay()" type="button" class="btn btn-outline-primary px-2 py-1 mx-1 width-botton mb-1" title="Descargar Comp. Pago">
                    <em class="pi pi-download mr-1 font-weight-bold" style="font-size: 13px;"></em>
                </button> 

                <!-- Finish admin options -->
                
                <!-- Client options -->
                
                <!-- Upload pay document -->
                <button id="payDocument" *ngIf="this.order.status.code == '2' && !this.optionsAdmin" (click)="uploadDocument()"
                    type="button" class="btn btn-outline-success px-2 py-1 mx-1 mb-1" title="Subir documento de pago">
                    <em class="pi pi-dollar mr-1 font-weight-bold" style="font-size: 13px;"></em>&nbsp;Pagar
                </button>
                <button id="payDocumentCredit" *ngIf="!this.optionsAdmin" (click)="downloadDocumentCredit()" type="button"
                    class="btn btn-outline-primary px-2 py-1 mx-1 mb-1" title="Descargar Factura BDO">
                    <em class="pi pi-download mr-1 font-weight-bold" style="font-size: 13px;"></em>&nbsp;Factura BDO
                </button>
                <button id="taxDocument" *ngIf="!this.optionsAdmin && !this.order.validTax && this.business.businessType == 1
                                        && this.order.status.code != '99'" (click)="uploadTax()" type="button"
                    class="btn btn-light px-2 py-1 mx-1 border titles list-buttons mb-1" title="Subir documento de impuesto">
                    <em class="pi pi-file font-weight-bold" style="font-size: 13px;"></em>
                </button>
                <button *ngIf="this.order.status.code <= '4'" type="button" data-toggle="modal" data-target="#confirmationValidModal"
                    (click)="cancelOrder()" class="btn btn-light px-2 py-1 mx-1 border titles list-buttons mb-1"
                    title="Anular orden de compra">
                    <em class="pi pi-times-circle font-weight-bold" style="font-size: 13px;"></em>
                </button>
                <!-- Finish client options -->
            </div>
        </div>
    </div>
</div>
