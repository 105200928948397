import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS_ADMIN: NbMenuItem[] = [
  {
    title: 'Administración',
    icon: 'people-outline',
    children: [
      {
        title: 'Empresas',
        link: '/app/business',
      },
      {
        title: 'Instancias',
        link: '/app/instance',
      },
      {
        title: 'Usuarios',
        link: '/app/users',
      },
      {
        title: 'Roles',
        link: '/app/roles',
      },
    ],
  },
  {
    title: 'Productos',
    icon: 'grid-outline',
    link:  '/app/product',
  },
  {
    title: 'Etiquetas',
    icon: 'credit-card-outline',
    children: [
      {
        title: 'Generación Etiquetas',
        link: '/app/dashboard/process',
      },
      {
        title: 'Activación Etiquetas',
        link: '/app/product-label',
      },
      {
        title: 'Verificación Etiquetas',
        link: '/app/dashboard/process',
      },
      {
        title: 'Etiquetas Rotas',
        link: '/app/dashboard/process',
      },
    ],
  },
];

// export const MENU_ITEMS_SECOND_ADMIN: NbMenuItem[] = [
//   ...MENU_ITEMS_RAW_MATERIAL,
//   MENU_ITEMS_BREEDING[1],
//   MENU_ITEMS_BREEDING[2],
//   MENU_ITEMS_BREEDING[3],
//   ...MENU_ITEMS_PRODUCT_FINAL,
// ];
