import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { IActivationResponse } from 'app/shared/models/activation-data.model';

@Component({
  selector: 'ngx-modal-response',
  templateUrl: './modal-response.component.html',
  styleUrls: ['./modal-response.component.scss'],
})
export class ModalResponseComponent implements OnInit, OnDestroy {


  messageInactivity = 'Resumen';
  @Input() visible: boolean;
  @Input() activationResponse: IActivationResponse;

  constructor(
    private router: Router,
    private location: Location,

  ) {
    this.visible = false;
  }

  ngOnInit(): void {
    this.visible = false;

  }
  ngOnDestroy(): void {
    this.activationResponse;
    this.visible = false;
  }

  onAccept() {
    this.visible = false;
    this.activationResponse.generatedMaps = [];
    this.activationResponse.raw = [];
    this.activationResponse.totalNotUpdate = 0;
    this.activationResponse.totalUpdate = 0;
    this.router.navigate(['/app']);
    // this.backClicked();
  }
  backClicked() {
    this.location.back();
  }
}
