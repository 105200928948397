import { Component, EventEmitter, Input, OnInit, OnChanges, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'ngx-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnChanges {

  @Input() value: string = 'Buscar...';
  @Output() SearchEmitter: EventEmitter<string> = new EventEmitter();

  search = new FormControl('');

  constructor() { }

  ngOnInit(): void {
    this.search.valueChanges.pipe(debounceTime(300)).subscribe(value => { this.SearchEmitter.emit(value); });
  }

  ngOnChanges(): void {
    const inputSearch: any = document.querySelector('#searchInput');
    inputSearch.value = this.value;
  }
}
