import { ICatalogData, ICatalogDetail } from 'app/shared/models/catalog.model';
import { Injectable } from '@angular/core';
import { config } from 'app/config';
import { HttpClient } from '@angular/common/http';
import { OptionQuery } from '../models/option-query.model';

const BASE_URL = `${config.api.url}${config.catalogDetail.mainEndpoint}`;

@Injectable({
  providedIn: 'root',
})

export class CatalogDetailService {

  constructor(protected http: HttpClient) { }
  /**
   * Obtener catalogos
   *
   * @param {string} catalogCode código del detalle padra
   * @param {string} [fatherCode]
   * @return {*}
   * @memberof CatalogService
   */
  get(codeParent: string, status: boolean) {
    const query = `${BASE_URL}${config.catalogDetail.ordered}${codeParent}/${status}`;
    return this.http.get(query);
  }
  getByCatalogId(optionQuery: OptionQuery, id: string) {
    let query = `${BASE_URL}${config.catalogDetail.catalog}${id}?relations=catalogDetail&`;
    if (optionQuery.offset && optionQuery.limit) {
      query += `offset=${optionQuery.offset}&limit=${optionQuery.limit}&`;
    }
    if (optionQuery.sortDirection && optionQuery.sortField)
      query += `sortField=${optionQuery.sortField}&sortDirection=${optionQuery.sortDirection}&`;
    if (optionQuery.filterField && optionQuery.filterValue)
      query += `filterField=${optionQuery.filterField}&filterValue=${optionQuery.filterValue}&`;
    if (optionQuery.boolField && optionQuery.boolValue !== undefined)
      query += `boolField=${optionQuery.boolField}&boolValue=${optionQuery.boolValue}`;
    return this.http.get(query);
  }
  public getAllCatalogDetails(optionQuery: OptionQuery) {
    let query = `${BASE_URL}?`;
    if (optionQuery.offset && optionQuery.limit) {
      query += `offset=${optionQuery.offset}&limit=${optionQuery.limit}&`;
    }
    if (optionQuery.sortDirection && optionQuery.sortField)
      query += `sortField=${optionQuery.sortField}&sortDirection=${optionQuery.sortDirection}&`;
    if (optionQuery.filterField && optionQuery.filterValue)
      query += `filterField=${optionQuery.filterField}&filterValue=${optionQuery.filterValue}&`;
    if (optionQuery.boolField && optionQuery.boolValue !== undefined)
      query += `boolField=${optionQuery.boolField}&boolValue=${optionQuery.boolValue}`;
    return this.http.get(query);
  }
  public updateStatus(id: string) {
    return this.http.put(`${BASE_URL}${config.catalog.active}${config.catalog.status}`, { id: id });
  }

  public create(data: ICatalogDetail) {
    return this.http.post(`${BASE_URL}`, data);
  }

  public update(data: ICatalogDetail, id: string) {
    return this.http.put(`${BASE_URL}${id}`, data);
  }

  public getById(id: string) {
    return this.http.get(`${BASE_URL}${id}?relations=catalog`);
  }
  getCatalogDetailById(catalogDetId?: string, status?: boolean) {
    const query = `${BASE_URL}${config.catalogDetail.mainEndpoint}${catalogDetId}/${status}`;
    return this.http.get(query);
  }
  getCatalogDetailByIdAndCatalogName(catalogDetId?: string, catalogName?: string, status?: boolean) {
    const query = `${BASE_URL}${config.catalogDetail.mainEndpoint}${catalogDetId}/${catalogName}/${status}`;
    return this.http.get(query);
  }
  getCatalogDetailByIdFilter(catalogDetId?: string, status?: boolean) {
    const query = `${BASE_URL}${config.catalogDetail.mainEndpoint}${catalogDetId}/${status}`;
    return this.http.get(query);
  }
}
