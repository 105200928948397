import { Injectable } from '@angular/core';


@Injectable()

export class UtilitiesService {
    private patternSoloNumeros = /^[0-9.]/;
    private patternDigitos = /^[0-9]/;
    private patternNumeroLetras = /^[a-zA-Z0-9]/;
    private readonly patternLetrasEspacio = /^[a-zA-Z0-9 ]/;
    private readonly patternLetrasNumeros = /^[a-zA-Z0-9]/;
    private readonly patternNumerosLetrasMinusculas = /^[a-z0-9]/;
    private readonly patternNumerosLetrasMayusculas = /^[0-9-A-Z]/;
    private readonly patternNumerosYGuion = /^[0-9-]/;
    private readonly patternNumbersLettersLowercaseUnderscore = /^[a-z0-9_]/;

    constructor() {
    }

    soloNumeros(event: any) {
        const inputChar = String.fromCharCode(event.charCode);
        if (event.charCode !== 0) {
            if (!this.patternSoloNumeros.test(inputChar.toString())) {
                event.preventDefault();
            }
        }
    }

    soloLetrasNumeroEspacio(event: any) {
        const inputChar = String.fromCharCode(event.charCode);
        if (event.charCode !== 0) {
            if (!this.patternLetrasEspacio.test(inputChar.toString())) {
                event.preventDefault();
            }
        }
    }

    soloNumeroYLetras(event: any) {
        const inputChar = String.fromCharCode(event.charCode);
        if (event.charCode !== 0) {
            if (!this.patternNumeroLetras.test(inputChar.toString())) {
                event.preventDefault();
            }
        }
    }

    soloDigitos(event: any) {
        const inputChar = String.fromCharCode(event.charCode);
        if (event.charCode !== 0) {
            if (!this.patternDigitos.test(inputChar.toString())) {
                event.preventDefault();
            }
        }
    }
    soloLetrasNumeroYGuion(event: any) {
        const inputChar = String.fromCharCode(event.charCode);
        if (event.charCode !== 0) {
            if (!this.patternNumerosYGuion.test(inputChar.toString())) {
                event.preventDefault();
            }
        }
    }
    // minusculas - letras numeros sin espacio ni caracteres especiales
    soloLetrasNumeroMinusculas(event: any) {
        const inputChar = String.fromCharCode(event.charCode);
        if (event.charCode !== 0) {
            if (!this.patternNumerosLetrasMinusculas.test(inputChar.toString())) {
                event.preventDefault();
            }
        }
    }
    // mayusculas - letras numeros sin espacio ni caracteres especiales
    soloLetrasNumeroMayusculas(event: any) {
        const inputChar = String.fromCharCode(event.charCode);
        if (event.charCode !== 0) {
            if (!this.patternNumerosLetrasMayusculas.test(inputChar.toString())) {
                event.preventDefault();
            }
        }
    }
    // letras numeros sin espacio ni caracteres especiales
    soloLetrasNumeros(event: any) {
        const inputChar = String.fromCharCode(event.charCode);
        if (event.charCode !== 0) {
            if (!this.patternLetrasNumeros.test(inputChar.toString())) {
                event.preventDefault();
            }
        }
    }

    onlyWordAndUnderscoren(event: any) {
        const inputChar = String.fromCharCode(event.charCode);
        if (event.charCode !== 0) {
            if (!this.patternNumbersLettersLowercaseUnderscore.test(inputChar.toString())) {
                event.preventDefault();
            }
        }
    }

    // Función para permitir solo números del 1 al 12
    soloNumerosMeses(event: KeyboardEvent) {
        const inputChar = (event.target as HTMLInputElement).value;
        const regex = /^(1[0-2]?|[1-9])$/;
        if (!regex.test(inputChar)) {
          (event.target as HTMLInputElement).value = '';
        }
    }

}
