import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IDirections } from 'app/shared/models/directions.model';
import { DirectionsService } from 'app/shared/services/directions.service';

@Component({
  selector: 'ngx-direction-card',
  templateUrl: './direction-card.component.html',
  styleUrls: ['./direction-card.component.scss'],
})
export class DirectionCardComponent implements OnChanges {
  @Input() active: any = false;
  @Input() page: string = '';
  @Input() invoicedData!: IDirections;
  @Input() businessName: string;
  @Input() businessRuc: string;
  @Input() businessId: string;
  @Output() invoiceDirection: EventEmitter<any> = new EventEmitter();

  invoiceForm: FormGroup = this.formBuilder.group({
    id: [''],
    phone: ['', [Validators.required, Validators.pattern('[0-9+ 0-9- 0-9() ]+')]],
    email: ['', [Validators.required]],
    direction: ['', [Validators.required]],
  });

  existInfo: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnChanges(): void {
    const businessName: any = document.querySelector('#businessName');
    const businessRuc: any = document.querySelector('#businessRuc');
    businessName.value = this.businessName;
    businessRuc.value = this.businessRuc;
    this.invoiceForm.patchValue({
      businessId: this.businessId,
    });
    if (this.active === 'true') {
      this.invoiceForm.get('phone').disable({ onlySelf: true });
      this.invoiceForm.get('email').disable({ onlySelf: true });
      this.invoiceForm.get('direction').disable({ onlySelf: true });
    }
    if (this.page === 'GenerateOrder') {
      document.querySelector('#collapseOne').classList.add('show');
    }
    if (this.invoicedData) {
      let id: string, direction: string, email: string, phone: string;
      ({
        id, direction, email, phone,
      } = this.invoicedData);
      this.invoiceForm.setValue({
        id, direction, email, phone,
      });
      this.existInfo = true;
      this.sendInformation();
    }
    this.invoiceForm.markAllAsTouched();
    this.sendInformation();
  }

  // Function to validate form and check if the previous information is the same
  sendInformation() {
    if (this.existInfo) {
      if (this.invoiceForm.valid) {
        const { direction, phone, email } = this.invoiceForm.value;
        this.invoicedData.direction === null ? '' : this.invoicedData.direction;
        this.invoicedData.phone === null ? '' : this.invoicedData.phone;
        this.invoicedData.email === null ? '' : this.invoicedData.email;
        if (direction === this.invoicedData.direction && phone === this.invoicedData.phone &&
          email === this.invoicedData.email) {
          this.invoiceDirection.emit(null);
        } else {
          this.invoiceDirection.emit([this.invoiceForm.value, 'Update invoice direction']);
        }
      } else {
        this.invoiceDirection.emit('Invalid Form');
      }
    } else {
      if (this.invoiceForm.valid) {
        this.invoiceDirection.emit([this.invoiceForm.value, 'Create invoice direction']);
      } else {
        this.invoiceDirection.emit('Invalid Form');
      }
    }
  }
}
