import { Injectable } from '@angular/core';
import { config } from 'app/config';
import { HttpClient } from '@angular/common/http';
import { IDocument, IDocumentResponse } from '../models/document.model';
import { OptionQuery } from '../models/option-query.model';

const BASE_URL = `${config.api.url}${config.document.mainEndpoint}`;

@Injectable({
  providedIn: 'root',
})

export class DocumentService {

  constructor(protected http: HttpClient) { }

  public updateStatus(id: string) {
    return this.http.put(`${BASE_URL}${config.catalog.active}${config.catalog.status}`, { id: id });
  }

  public postDocument(file: any, businessId: string, catalogId: string, sourceDocumentType: string) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('catalogId', catalogId);
    formData.append('businessId', businessId);
    formData.append('sourceDocumentType', sourceDocumentType);
    return this.http.post<IDocumentResponse>(`${BASE_URL}`, formData);
  }

  public create(data: IDocument) {
    return this.http.post(`${BASE_URL}`, data);
  }

  public update(data: IDocument, id: string) {
    return this.http.put(`${BASE_URL}${id}`, data);
  }

  public get() {
    return this.http.get(`${BASE_URL}`);
  }

  public getDocuments(optionQuery: OptionQuery) {
    let query = `${BASE_URL}?`;
    if (optionQuery.offset && optionQuery.limit) {
      query += `offset=${optionQuery.offset}&limit=${optionQuery.limit}&`;
    }
    if (optionQuery.sortDirection && optionQuery.sortField)
      query += `sortField=${optionQuery.sortField}&sortDirection=${optionQuery.sortDirection}&`;
    if (optionQuery.filterField && optionQuery.filterValue)
      query += `filterField=${optionQuery.filterField}&filterValue=${optionQuery.filterValue}&`;
    if (optionQuery.boolField && optionQuery.boolValue !== undefined)
      query += `boolField=${optionQuery.boolField}&boolValue=${optionQuery.boolValue}`;
    return this.http.get(query);
  }

  public getDocumentsByCatalog(catalogId: string, optionQuery: OptionQuery) {
    let query = `${BASE_URL}${config.document.catalogDetail}${catalogId}?`;
    if (optionQuery.offset && optionQuery.limit) {
      query += `offset=${optionQuery.offset}&limit=${optionQuery.limit}&`;
    }
    if (optionQuery.sortDirection && optionQuery.sortField)
      query += `sortField=${optionQuery.sortField}&sortDirection=${optionQuery.sortDirection}&`;
    if (optionQuery.filterField && optionQuery.filterValue)
      query += `filterField=${optionQuery.filterField}&filterValue=${optionQuery.filterValue}&`;
    if (optionQuery.boolField && optionQuery.boolValue !== undefined)
      query += `boolField=${optionQuery.boolField}&boolValue=${optionQuery.boolValue}`;
    return this.http.get(query);
  }

  downloadS3ById(id: string) {
    return this.http.get(`${BASE_URL}${config.document.download}${id}`, { responseType: 'blob' });
  }

  getDocumentById(documentId: string) {
    return this.http.get(`${BASE_URL}${documentId}`);
  }

  public deleteDocument(documentId: string) {
    return this.http.delete(`${BASE_URL}${documentId}`);
  }
}
