import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ngx-modal-loading',
  templateUrl: './modal-loading.component.html',
  styleUrls: ['./modal-loading.component.scss'],
})
export class ModalLoadingComponent implements OnInit {

  @Input() visible: boolean;
  @Input() cancellable?: boolean;
  @Output() onCancellable = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  onCancel() {
    this.onCancellable.emit();
  }

}
