import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss'],
})
export class StatusBarComponent implements OnChanges {

  @Input() status!: string;
  @Input() icon!: string;
  @Input() order!: any;
  @Input() purchaseOrder!: any; // para validar businessCredit
  @Input() ordersTotal!: [];
  actualStateNumber: number = 0;

  constructor() { }

  ngOnChanges(): void {
    this.deleteNullEstate();
  }

  // Delete state "ANULADO" for generate component
  // @autor: Daniel Tapia López
  // @date: 02-08-2022
  deleteNullEstate() {
    for (let i = 0; i < this.ordersTotal.length; i++) {
      const element: any = this.ordersTotal[i];
      if (element.name === 'ANULADO') {
        this.ordersTotal.splice(i, 1);
      }
    }
  }
}
