export const environment = {
  production: false,
  apiUrl: 'api/v1/',
  apiAuth: 'https://auth.test1.safetrack.cloud',
  apiSafetrack: 'https://test1.safetrack.cloud',
  firebaseConfig: {
    apiKey: 'AIzaSyD7ui_03IrdXYAHoyGodUTIBqASnoBBZjE',
    authDomain: 'safetrack-7c483.firebaseapp.com',
    databaseURL: 'https://safetrack-7c483-default-rtdb.firebaseio.com',
    projectId: 'safetrack-7c483',
    storageBucket: 'safetrack-7c483.appspot.com',
    messagingSenderId: '349988615516',
    appId: '1:349988615516:web:446530d474fcc6dc54bd99',
    measurementId: 'G-Z8VSBZTKP7',
  },
  firebaseCollection: 'pending-dev',
  validateTags: 'https://s4t.io/',
};
