import { SortDirection } from '../enums/sort-direction.enum';

export class OptionQuery {
  sortDirection?: SortDirection;
  sortField?: string;
  filterField?: string;
  filterValue?: string;
  limit?: number;
  offset?: number;
  boolField?: string;
  boolValue?: boolean;
}
