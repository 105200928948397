export const FREE_SHIPPING: number = 100000;
export const SHIPPING_COST: number = 4;
export const PRODUCER_BUSINESS: string = '0';
export const IMPORTER_BUSINESS: string = '1';
export const FILTER_FIELD_BUSINESS: string = 'business.name';
export const FILTER_FIELD_STATUS: string = 'status.name';
export const SORT_SECUENCE: string = 'secuence';
export const ORDER_BUSINESS: string = 'status';
export const ORDER_STATUS: string = 'business';
export const ORDER_ASC: string = 'ASC';
export const ORDER_DESC: string = 'DESC';
export const OBSERVATION_PURCHASE_ORDER_PAY: string = 'Pago realizado';
export const OBSERVATION_PURCHASE_ORDER_INVOCED_UPLOAD: string = 'Su factura ha sido generada';
export const IVA: number = 0.15;
