import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

export enum toastTypes {
  success,
  warning,
  error,
}

export interface ToastData {
  title: string;
  message: string;
  show?: boolean;
  type?: toastTypes;
  progressWidth?: string;
  timeout?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ToastCentralService {
  data!: ToastData;
  currentPage: string;
  public open = new Subject<ToastData>();

  constructor(
    private router: Router,
  ) {

  }

  // Instantiate and initialize the toast
  // @autor: Carlos Sánchez Mora
  // @date: 29-07-2022
  initiate(data: ToastData, page: string) {
    if (!data.timeout) {
      data.timeout = 2500;
    }
    this.data = { ...data, show: true, progressWidth: '100%' };
    this.open.next(this.data);
    this.currentPage = page;
  }

  // Close the toast
  // @autor: Carlos Sánchez Mora
  // @date: 29-07-2022
  hide() {
    this.data = { ...this.data, show: false };
    this.open.next(this.data);
    if (this.currentPage === 'generateOrder') {
      // this.router.navigate(['/app/purchase-orders']);
    }
  }
}
