import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { NbNativeDateService } from '@nebular/theme';

@Injectable({
    providedIn: 'root',
})

export class CalendarService {

    constructor(private datePipe: DatePipe, private nativeDateService: NbNativeDateService) { }

    getWeekDayYear(date: Date) {
        let day = date.getDay();
        const week = this.getWeekNumber(date);
        if (day === 0) {
            day = 7;
        }
        const year = date.getFullYear();
        return `${week}.${day}.${year}`;
    }

    /**
     * Obtener listado de batchs de un rango de fecha
     * @param dateStart
     * @param dateEnd
     * @returns
     */
    getBatchs(dateStart: Date, dateEnd: Date) {
        const batchs = [];
        const days = this.differencesDays(dateStart, dateEnd);
        batchs.push(this.getWeekDayYear(dateStart));
        for (let i = 0; i < days; i++) {
            dateStart = this.nativeDateService.addDay(dateStart, 1);
            batchs.push(this.getWeekDayYear(dateStart));
        }
        return batchs;
    }

    /**
     * Obtener número de semana de una fecha
     * @param date
     * @returns
     */
    private getWeekNumber(date: Date): number {
        const dateNew = new Date(date);
        dateNew.setHours(0, 0, 0, 0);
        dateNew.setDate(dateNew.getDate() - 1);
        return parseInt(this.datePipe.transform(dateNew, 'w'), 10);
    }

    /**
     * Obtener diferencias de días entre dos fechas
     * @param a
     * @param b
     * @returns
     */
    private differencesDays(a: Date, b: Date) {
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        return Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24));
    }
}
