import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'environments/environment';

@Component({
  selector: 'ngx-label-validator',
  templateUrl: './label-validator.component.html',
  styleUrls: ['./label-validator.component.scss'],
})
export class LabelValidatorComponent implements OnInit {

  form: FormGroup;

  captcha: string;
  email: string;
  siteKey: string = '6LelhWMiAAAAAGyRvjh5EjnHPFoXcWqUUQlDRRpl';

  constructor(
    private formBuilder: FormBuilder,
  ) {
    this.captcha = '';
    this.email = 'soporte@zeyo.io';
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      uniqueMark: [null, [Validators.required, Validators.pattern(/^[0-9a-zA-Z]+$/)]],
      recaptchaReactive: [null, Validators.required],
    });
  }
  onValidateTicket() {
    const data = this.form.value;
    const url = `${environment.validateTags}${data.uniqueMark.toUpperCase()}`;
    window.open(url, '_blank');
  }
  resolved(captchaResponse: string) {
    this.captcha = captchaResponse;
  }
}
