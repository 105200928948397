import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'ngx-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
})
export class SearchSelectComponent implements OnChanges {

  @Input() values!: any[];
  @Input() disabled: boolean = false;
  @Input() error!: any;
  @Output() selected = new EventEmitter<any>();
  settings = {
    options: false,
  };

  filters = {
    search: '',
  };

  // It checks if there is a change and generates an event listener for when it leaves focus closes the list
  // @autor: Carlos Sánchez Mora
  // @date: 27-07-2022
  ngOnChanges(): void {
    const element: any = document.getElementById('selected');
    element.addEventListener(
      'blur',
      () => {
        setTimeout(() => {
          this.settings.options = false;
        }, 100);
      },
      true,
    );
  }

  // Open list and render to input size
  // @autor: Carlos Sánchez Mora
  // @date: 27-07-2022
  toogle(open?: boolean): void {
    if (open) {
      this.settings.options = true;
    } else {
      this.settings.options = !this.settings.options;
    }
    setTimeout(() => {
      const element: any = document.getElementById('selected');
      const datalist: any = document.getElementById('lista');
      datalist.style.cssText = 'width: ' + element.offsetWidth + 'px';
    }, 1);
  }

  // Select the option and choose
  // @autor: Carlos Sánchez Mora
  // @date: 27-07-2022
  choose(item: any): void {
    this.settings.options = false;
    this.filters.search = item.label;
    this.selected.emit(item);
  }

}
