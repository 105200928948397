import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum AlertTypes {
  success,
  warning,
  error,
}
export interface AlertData {
  title: string;
  subtitle?: string;
  icon?: string;
  message?: string;
  confirmButtonText: string;
  cancelButtonText?: string;

  show?: boolean;
  type: AlertTypes;
}

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  data!: AlertData;
  public open = new Subject<any>();

  // Instantiate and initialize the alert
  // @autor: Carlos Sánchez Mora
  // @date: 02-08-2022
  initiate(data: AlertData): any {
    this.data = { ...data, show: true };
    this.open.next(this.data);
  }

  // Close the alert
  // @autor: Carlos Sánchez Mora
  // @date: 02-08-2022
  hide(response: boolean): any {
    this.data = { ...this.data, show: false };
    this.open.next(response);
  }
}
