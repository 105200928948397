import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ngx-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {

  @Input() totalItems: number = 0;
  @Input() itemsPerPage: number = 0;
  @Output() onNewPage: EventEmitter<number> = new EventEmitter<number>();
  pages = [];
  totalPages: number;
  currentPage: number = 0;

  constructor() { }

  ngOnChanges(): void {
    this.totalPages = 0;
    this.pages = [];
    this.totalPages = Number((this.totalItems / this.itemsPerPage));
    const decimalPart = this.totalPages % 1;
    if (decimalPart !== 0) this.totalPages = (this.totalPages - decimalPart) + 1;
    for (let i = 0; i < this.totalPages; i++) {
      let statusPage = false;
      if (i === 0) statusPage = true;
      this.pages.push({
        name: i,
        active: statusPage,
      });
    }
    this.totalPages--;
    if (this.totalPages > 0) {
      setTimeout(() => {
        document.querySelector('#pagina0').classList.add('btn-active');
      }, 2000);
    }
  }

  // Set pagination actions for charge diferents pages in table
  // @autor: Daniel Tapia López
  // @date: 03-08-2022
  firstPage() {
    this.deactivate();
    this.currentPage = 0;
    document.querySelector(`#pagina${this.currentPage}`).classList.add('btn-active');
    this.onNewPage.emit(1);
  }

  previousPage() {
    if (this.currentPage - 1 >= 0) {
      this.deactivate();
      this.currentPage--;
      document.querySelector(`#pagina${this.currentPage}`).classList.add('btn-active');
      this.onNewPage.emit(this.currentPage + 1);
    }
  }

  nextPage() {
    if (this.currentPage + 1 <= this.totalPages) {
      this.deactivate();
      this.currentPage++;
      document.querySelector(`#pagina${this.currentPage}`).classList.add('btn-active');
      this.onNewPage.emit(this.currentPage + 1);
    }
  }

  lastPage() {
    this.deactivate();
    this.currentPage = this.totalPages;
    document.querySelector(`#pagina${this.currentPage}`).classList.add('btn-active');
    this.onNewPage.emit(this.totalPages + 1);
  }

  goToPage(page: number) {
    this.deactivate();
    this.currentPage = page;
    this.currentPage--;
    document.querySelector(`#pagina${this.currentPage}`).classList.add('btn-active');
    this.onNewPage.emit(this.currentPage + 1);
  }

  deactivate() {
    const matches = document.querySelectorAll('ul.pagination,li.page-item');
    for (let i = 0; i < matches.length; i++) {
      const element = matches[i];
      element.classList.remove('btn-active');
    }
  }

}
