<p-dataView #dv [loading]="!isLoaded" [value]="users" filterBy="detail" [sortField]="sortField" [sortOrder]="sortOrder"
    layout="grid">

    <ng-template pTemplate="header" class="header-data">
        <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
            <p-dropdown [options]="sortOptions" placeholder="Ordenar por usuario" (onChange)="onSortChange($event)"
                styleClass="p-mb-2 p-mb-md-0"></p-dropdown>
            <p-dropdown [options]="statusOptions" placeholder="Filtrar usuarios" (onChange)="onStatusChange($event)"
                styleClass="p-mb-2 p-mb-md-0"></p-dropdown>
            <!--Buscador-->
            <span class="p-input-icon-left p-mb-2 p-mb-md-0">
                <i class="pi pi-search"></i>
                <input type="text" pInputText placeholder="Buscar por usuario" [(ngModel)]="optionQuery.filterValue"
                    (ngModelChange)="this.userSearchUpdate.next($event)" class="p-inputtext p-component">
            </span>
            <!--End buscador-->
            <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
        </div>
    </ng-template>

    <ng-template let-item pTemplate="listItem">
        <div class="p-col-12">
            <div class="item-list-item list">
                <div class="item-list-detail" (click)="onSelect(item)">
                    <div class="p-grid">
                        <div *ngIf="!show" class="p-col-2">
                            <div class="header-title">Username</div>
                            <div class="item-list-title"
                                [pTooltip]="((item.username !== null) ? item.username : 'Sin título' !== null) ? (item.username !== null) ? item.username : 'Sin título' : 'Sin título'">
                                <a class="color-a" [routerLink]="['../',item.id,'view']">{{(item.username !== null) ?
                                    item.username: 'Sin nombre'}}</a>
                            </div>
                        </div>
                        <div *ngIf="show" class="p-col-2">
                            <div class="header-title">Username</div>
                            <div class="item-list-title"
                                [pTooltip]="((item.username !== null) ? item.username : 'Sin título' !== null) ? (item.username !== null) ? item.username : 'Sin título' : 'Sin título'">
                                <a class="color-a" [routerLink]="['../../../','users',item.id,'view']">{{(item.username
                                    !== null) ?
                                    item.username: 'Sin nombre'}}</a>
                            </div>
                        </div>
                        <div class="p-col-2">
                            <div class="header-title">Nombres</div>
                            <div class="item-list-title"
                                [pTooltip]="((item.firstName !== null) ? item.firstName : 'Sin título' !== null) ? (item.firstName !== null) ? item.firstName : 'Sin título' : 'Sin título'">
                                {{(item.firstName !== null) ? item.firstName: 'Sin nombre'}}
                            </div>
                        </div>
                        <div class="p-col-3">
                            <div class="header-title">Apellidos</div>
                            <div class="item-list-title"
                                [pTooltip]="((item.lastName !== null) ? item.lastName : 'Sin título' !== null) ? (item.lastName !== null) ? item.lastName : 'Sin título' : 'Sin título'">
                                {{(item.lastName !== null) ? item.lastName: 'Sin nombre'}}
                            </div>
                        </div>
                        <div class="p-col-4">
                            <div class="header-title">Correo Electrónico</div>
                            <div class="item-list-title">{{item.email}}</div>
                        </div>
                    </div>
                </div>
                <!--Botones-->
                <div class="item-list-action">
                    <div class="p-col">
                        <button *ngIf="!show" type="button" nbButton nbTooltip="Detalles" nbTooltipPlacement="top"
                            size="small" class="p-button p-mr-2" [routerLink]="['../',item.id,'view']">
                            <nb-icon icon="menu-outline"></nb-icon>
                        </button>
                        <button *ngIf="show" type="button" nbButton nbTooltip="Detalles" nbTooltipPlacement="top"
                            size="small" class="p-button p-mr-2" [routerLink]="['../../../','users',item.id,'view']">
                            <nb-icon icon="menu-outline"></nb-icon>
                        </button>
                        <button *ngIf="!show" type="button" nbButton nbTooltip="Editar" nbTooltipPlacement="top"
                            size="small" [routerLink]="['../',item.id,'edit']" class="p-button p-mr-2"
                            [disabled]="item.status===false">
                            <nb-icon icon="edit-outline"></nb-icon>
                        </button>
                        <button *ngIf="show" type="button" nbButton nbTooltip="Editar" nbTooltipPlacement="top"
                            size="small" [routerLink]="['../../../','users',item.id,'edit']" class="p-button p-mr-2"
                            [disabled]="item.status===false">
                            <nb-icon icon="edit-outline"></nb-icon>
                        </button>
                        <p-inputSwitch [(ngModel)]="item.status" [disabled]='false' nbTooltip="Activar/Desactivar"
                            (onChange)="handleChange($event, item.id)">
                        </p-inputSwitch>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template let-item pTemplate="gridItem">
        <div class="p-col-12 p-md-4 p-lg-4">
            <div class="item-grid-item card">
                <div class="item-grid-item-content" (click)="onSelect(item)">
                    <div *ngIf="!show" class="p-grid">
                        <div class="p-col item-title"
                            [pTooltip]="((item.username !== null) ? item.username : 'Sin título' !== null) ? (item.username !== null) ? item.username : 'Sin título' : 'Sin título'">
                            <a class="color-a" [routerLink]="['../',item.id,'view']">{{(item.username !== null) ?
                                item.username : 'Sin título'}}</a>
                        </div>
                    </div>
                    <div *ngIf="show" class="p-grid">
                        <div class="p-col item-title"
                            [pTooltip]="((item.username !== null) ? item.username : 'Sin título' !== null) ? (item.username !== null) ? item.username : 'Sin título' : 'Sin título'">
                            <a class="color-a" [routerLink]="['../../../','users',item.id,'view']">{{(item.username !==
                                null) ?
                                item.username : 'Sin título'}}</a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="p-col-11">
                            <div *ngIf="item.businessId">
                                <div class="item-list-title"><i
                                        class="fa fa-building margin-icon"></i>{{item.business?.name}}</div>
                            </div>
                            <div class="item-list-title">{{item.firstName}} {{item.lastName}}</div>
                            <div class="item-list-title">{{item.phone}}</div>
                        </div>
                    </div>
                </div>
                <!--Botones-->
                <div class="card-footer item-list-action">
                    <button type="button" nbButton *ngIf="!show" nbTooltip="Detalles" status="info" size="small"
                        shape="round" class="p-button" [routerLink]="['../',item.id,'view']">
                        <nb-icon icon="menu-outline"></nb-icon>
                    </button>
                    <button type="button" *ngIf="show" nbButton nbTooltip="Detalles" status="info" size="small"
                        shape="round" class="p-button" [routerLink]="['../../../','users',item.id,'view']">
                        <nb-icon icon="menu-outline"></nb-icon>
                    </button>
                    <button type="button" *ngIf="show" nbButton nbTooltip="Editar" status="info" size="small"
                        shape="round" class="p-button" [routerLink]="['../../../','users',item.id,'edit']">
                        <nb-icon icon="edit-outline"></nb-icon>
                    </button>
                    <button type="button" *ngIf="!show" nbButton nbTooltip="Editar" status="info" size="small"
                        shape="round" class="p-button" [routerLink]="['../',item.id,'edit']">
                        <nb-icon icon="edit-outline"></nb-icon>
                    </button>
                    <p-inputSwitch [(ngModel)]="item.status" [disabled]='false' nbTooltip="Activar/Desactivar"
                        (onChange)="handleChange($event, item.id)">
                    </p-inputSwitch>
                    <ng-template #elseBlock> </ng-template>
                </div>
            </div>
        </div>
    </ng-template>

</p-dataView>
<p-paginator [rows]="limitTable" [totalRecords]="totalRecords" (onPageChange)="onPaginate($event)">
</p-paginator>