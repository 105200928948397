<p-dialog header="Llave privada" [(visible)]="visible" [style]="{width: '50vw'}" [modal]="true" [closable]="false">
    <form [formGroup]="formKey">
        <div class="form-group col-12">
            <textarea pInputTextarea formControlName="privateKey" placeholder="Ingrese llave privada"
                id="input-privateKey" class="form-control"></textarea>

        </div>
    </form>
    <ng-template pTemplate="footer">
        <button nbButton status="info" outline size="small" shape="round"
            class="p-button  p-mr-2 p-mb-2 button-outline" (click)="onCancel()">
            Cancelar
        </button>
        <button nbButton (click)="onSavePrivateKey()" status="info" size="small" shape="round"
            class="p-button  p-mr-2 p-mb-2 modal-button-size" [disabled]="formKey.invalid">
            Guardar
        </button>
    </ng-template>
</p-dialog>
