import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from 'app/config';
import { IUser } from '../models/user.model';
import { OptionQuery } from '../models/option-query.model';

const BASE_URL = `${config.api.url}${config.user.mainEndpoint}`;

@Injectable({
  providedIn: 'root',
})
export class UserService {

  constructor(private http: HttpClient) { }

  public delete(id: string) {
    return this.http.delete(`${BASE_URL}${id}`);
  }

  public updateStatus(id: string) {
    return this.http.put(`${BASE_URL}${config.user.active}${config.user.status}`, { id: id });
  }

  public getAll(optionQuery: OptionQuery, relations?: string) {
    let query = `${config.api.url}${config.user.userAll}?offset=${optionQuery.offset}&limit=${optionQuery.limit}`;
    if (optionQuery.sortDirection && optionQuery.sortField)
      query += `&sortField=${optionQuery.sortField}&sortDirection=${optionQuery.sortDirection}`;
    if (optionQuery.filterField && optionQuery.filterValue)
      query += `&filterField=${optionQuery.filterField}&filterValue=${optionQuery.filterValue}`;
    if (optionQuery.boolField && optionQuery.boolValue !== undefined)
      query += `&boolField=${optionQuery.boolField}&boolValue=${optionQuery.boolValue}`;
    if (relations)
      query += `&relations=${relations}`;
    return this.http.get(query);
  }
  public getAllByBusiness(optionQuery: OptionQuery, businessId: string, relations?: string) {
    let query = `${config.api.url}${config.user.mainEndpoint}${config.user.business}${businessId}?offset=${optionQuery.offset}&limit=${optionQuery.limit}`;
    if (optionQuery.sortDirection && optionQuery.sortField)
      query += `&sortField=${optionQuery.sortField}&sortDirection=${optionQuery.sortDirection}`;
    if (optionQuery.filterField && optionQuery.filterValue)
      query += `&filterField=${optionQuery.filterField}&filterValue=${optionQuery.filterValue}`;
    if (optionQuery.boolField && optionQuery.boolValue !== undefined)
      query += `&boolField=${optionQuery.boolField}&boolValue=${optionQuery.boolValue}`;
    if (relations)
      query += `&relations=${relations}`;
    return this.http.get(query);
  }

  public getAllByStatus(status: boolean, limit: number, page: number,
    q: string, field: string, orderBy: string, orderDirection: string) {
    let query = `${BASE_URL}${config.user.status}${status}?page=${page}&limit=${limit}`;
    if (orderDirection && orderBy) query += `&orderBy=${orderBy}&orderDirection=${orderDirection}`;
    if (q && field) query += `&q=${q}&field=${field}`;
    return this.http.get(query);
  }

  public getUserProfile(userId: string) {
    return this.http.get(`${BASE_URL}${config.user.profile}${userId}`);
  }

  public getAllNoPaginate() {
    return this.http.get(`${BASE_URL}${config.user.userAll}`);
  }

  public getAllByRole(code: string) {
    return this.http.get(`${BASE_URL}${config.register.role}${code}`);
  }

  public getById(id: string) {
    return this.http.get(`${BASE_URL}${id}`);
  }
  public getByIdUser(id: string) {
    return this.http.get(`${BASE_URL}${id}`);
  }

  public create(data: IUser) {
    return this.http.post(`${BASE_URL}`, data);
  }

  public update(data: IUser, id: string) {
    return this.http.put(`${BASE_URL}${id}`, data);
  }

  public getAsociateBusiness(id: string) {
    return this.http.get(`${BASE_URL}${id}?relations=business`);
  }
}
