import { Injectable } from '@angular/core';
import { config } from 'app/config';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtTokenService } from './jwt-token.service';
import { ILogin } from 'app/shared/models/login.model';

const CONTENT_TYPE_JSON = 'application/json';
const BASE_URL = config.api.url;
@Injectable({
  providedIn: 'root',
})
export class AuthService {


  private httpHeaders: HttpHeaders = new HttpHeaders({ 'Content-Type': CONTENT_TYPE_JSON });

  constructor(private http: HttpClient, private router: Router, private jwtTokenService: JwtTokenService) { }
  public login(data: ILogin) {
    return this.http.post(`${BASE_URL}${config.api.auth}`, data);
  }

  public decodeToken(): boolean {
    const token = localStorage.getItem('access_token');
    return this.jwtTokenService.decodeToken(token);
  }
  public logout(redirect?) {
    this.logoutKeyloak().subscribe(res => {
      window.sessionStorage.clear();
      if (redirect === true)
        this.router.navigate([`/auth`], { queryParams: { redirect: this.router.url } });
      else
        this.router.navigate([`/auth`]);
      localStorage.clear();
    }, err => {
      window.sessionStorage.clear();
      this.router.navigate([`/auth`]);
      localStorage.clear();
    });
  }
  private logoutKeyloak() {
    const refreshToken = localStorage.getItem('refresh_token');
    const data = { refreshToken };
    return this.http.post(`${config.oauth.remote}${config.oauth.logout}`, data);
  }
  isExistRole(role) {
    const token: any = this.jwtTokenService.decodeToken(localStorage.getItem('access_token'));
    const roles = token.realm_access.roles;
    const roleFind = roles.find(element => element === role);
    // if (roleFind) return true;
    // else return false;
    return true;
  }
  public profile() {
    this.router.navigate(['/app/account/profile']);
  }
  public recover_certificate() {
    this.router.navigate(['/app/account/recover-certificate']);
  }
  public change_password() {
    this.router.navigate(['/app/account/change-password']);
  }



  public refreshToken() {
    const data = {
      refreshToken: localStorage.getItem('refresh_token'),
      clientId: 'safetrack-cli',
      grantType: 'refresh_token',
    };
    return this.http.post(`${BASE_URL}${config.api.auth}`, data).
      pipe(
        tap((res: any) => {
          localStorage.setItem('access_token', res.access_token);
          localStorage.setItem('refresh_token', res.refresh_token);
        }),
      );
  }


  public getUserByCreateUser(create_user: string, limit: number, page: number,
    q: string, orderBy: string, orderDirection) {
    let params = `${create_user}?page=${page}&limit=${limit}`;
    if (q) params += `&q=${q}`;
    if (orderBy && orderDirection) params += `&orderBy=${orderBy}&orderDirection=${orderDirection}`;
    return this.http.get(`${config.oauth.urlApi}${config.
      register.mainEndpoint}${config.register.username}${params}`);
  }

  public getallAUsers(limit: number, page: number,
    q: string, orderBy: string, orderDirection) {
    let params = `?page=${page}&limit=${limit}`;
    if (q) params += `&q=${q}`;
    if (orderBy && orderDirection) params += `&orderBy=${orderBy}&orderDirection=${orderDirection}`;
    return this.http.get(`${config.oauth.urlApi}${config.
      register.userPaginate}${params}`);
  }

  public changePassword(data: any, userId: string) {
    return this.http.put(
      `${config.api.url}${config.user.mainEndpoint}${config.user.password}${userId}`,
      JSON.stringify(data),
      { headers: this.httpHeaders },
    );
  }

  public resetPassword(data: any) {
    return this.http.post(
      `${config.api.url}${config.user.resetPassword}`,
      JSON.stringify(data),
      { headers: this.httpHeaders },
    );
  }
  public getUserRoleLogin(): string {
    const token: any = this.jwtTokenService.decodeToken(localStorage.getItem('access_token'));
    return token['realm_access'].roles;
  }

  isAuthenticated(): boolean {
    try {
      const token = localStorage.getItem('access_token');
      const decodeToken = this.jwtTokenService.decodeToken(token);
      return decodeToken.preferred_username !== null;
    } catch {
      this.logout();
    }
  }

  getUsernameLogin(): string {
    const token: any = this.jwtTokenService.decodeToken(localStorage.getItem('access_token'));
    return token.preferred_username;
  }

  getUserIdLogin(): string {
    const token: any = this.jwtTokenService.decodeToken(localStorage.getItem('access_token'));
    return token?.sub;
  }
}



