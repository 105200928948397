import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { saveAs } from 'file-saver';
import { config } from 'app/config';
import { OptionQuery } from 'app/shared/models/option-query.model';
import { BusinessService } from 'app/shared/services/business.service';
import { CatalogDetailService } from 'app/shared/services/catalog-detail.service';
import { DocumentService } from 'app/shared/services/document.service';
import { ToastService } from 'app/@core/services/toast.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PurchaseOrderService } from 'app/shared/services/purchase-order.service';
import { OBSERVATION_PURCHASE_ORDER_INVOCED_UPLOAD, OBSERVATION_PURCHASE_ORDER_PAY } from 'app/shared/constants/purchase-order';
import { IUpdateDocumentPurchaseOrder } from 'app/shared/models/purchase-order.model';
import { superadmin } from 'app/shared/constants/roles.constants';

@Component({
  selector: 'ngx-upload-modal',
  templateUrl: './upload-modal.component.html',
  styleUrls: ['./upload-modal.component.scss'],
})
export class UploadModalComponent implements OnChanges {

  @Output() changeState: EventEmitter<any> = new EventEmitter();
  @Input() openModal: string;
  @Input() order: any;
  @Input() role!: string;

  // HTML Elements for styles
  dropArea: HTMLElement;
  dragText: HTMLElement;
  dragIcon: HTMLElement;
  uploadButton: HTMLElement;
  uploadInput: any;
  buttonModal: any;

  // Field in cache
  captureFile: any;
  documentsData = [];
  imageDocuments = [];
  maxDate: Date;

  // Forms
  invoiceForm: FormGroup = this.formBuilder.group({
    invoiceNumber: ['', [Validators.required, Validators.pattern('([0-9][0-9][0-9])-([0-9][0-9][0-9])-([0-9]+)')]],
    facturationDate: ['', [Validators.required]],
  });

  optionQueryCatalogDocument = new OptionQuery;
  multiplesDocumentsExist: boolean = false;
  loading: boolean = true;
  currentCatalogDetail: string = '';
  currentCatalogDetailCode: string = '';
  modalBDO: boolean = false;
  optionsAdmin: boolean = false;

  constructor(
    private catalogDetailService: CatalogDetailService,
    private documentService: DocumentService,
    private businessService: BusinessService,
    private toastDownService: ToastService,
    private formBuilder: FormBuilder,
    private purchaseOrderService: PurchaseOrderService,
  ) {
    this.buttonModal = document.getElementById('buttonModal');
  }

  ngOnChanges(): void {
    if (this.role === superadmin) {
      this.optionsAdmin = true;
    }
    this.loading = true;
    this.multiplesDocumentsExist = false;
    this.optionQueryCatalogDocument.filterField = config.catalogDetail.name;
    if (this.openModal === 'dai') {
      this.optionQueryCatalogDocument.filterValue = config.catalogDetail.taxesDocument;
      this.catalogDetailService.getAllCatalogDetails(this.optionQueryCatalogDocument).subscribe(resCatalogDetail => {
        const catalogDetail: any = resCatalogDetail;
        this.currentCatalogDetail = catalogDetail.data[0].id;
        this.loadDocuments(this.currentCatalogDetail);
        if (catalogDetail.data[0].aditionalInfo['multiples-doc']) {
          const btnModal = document.getElementById('buttonModal');
          btnModal.click();
        } else {
          const btnModalSingle = document.getElementById('buttonModalSingle');
          btnModalSingle.click();
        }
      });
    } else if (this.openModal === 'pay') {
      this.modalBDO = false;
      this.optionQueryCatalogDocument.filterValue = config.catalogDetail.payDocument;
      this.catalogDetailService.getAllCatalogDetails(this.optionQueryCatalogDocument).subscribe(resCatalogDetail => {
        const catalogDetail: any = resCatalogDetail;
        this.currentCatalogDetail = catalogDetail.data[0].id;
        this.currentCatalogDetailCode = catalogDetail.data[0].code;
        if (catalogDetail.data[0].aditionalInfo['multiples-doc']) {
          this.loadDocuments(catalogDetail.data[0].id);
          const btnModal = document.getElementById('buttonModal');
          btnModal.click();
        } else {
          this.loadComponents();
          this.clearComponent();
          const btnModalSingle = document.getElementById('buttonModalSingle');
          btnModalSingle.click();
        }
      });
    } else if (this.openModal === 'dboPay') {
      this.modalBDO = true;
      setTimeout(() => {
        const btnModal = document.getElementById('buttonModalInvoiceBdo');
        btnModal.click();
        this.optionQueryCatalogDocument.filterValue = config.catalogDetail.invoiceBDO;
        this.loadComponents();
        this.clearComponent();
      }, 100);
    } else if (this.openModal === 'validateDai') {
      this.optionQueryCatalogDocument.filterValue = config.catalogDetail.taxesDocument;
      this.catalogDetailService.getAllCatalogDetails(this.optionQueryCatalogDocument).subscribe(resCatalogDetail => {
        const catalogDetail: any = resCatalogDetail;
        this.currentCatalogDetail = catalogDetail.data[0].id;
        this.loadDocuments(this.currentCatalogDetail);
        if (catalogDetail.data[0].aditionalInfo['multiples-doc']) {
          const btnModal = document.getElementById('buttonModal');
          btnModal.click();
        } else {
          const btnModalSingle = document.getElementById('buttonModalSingle');
          btnModalSingle.click();
        }
      });
    }
  }
  blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }
  loadDocuments(catalogDetail: string) {
    this.imageDocuments = [];
    const optionQueryCatalogGetDocuments = new OptionQuery;
    optionQueryCatalogGetDocuments.filterField = config.document.documentTypeFilter;
    optionQueryCatalogGetDocuments.filterValue = this.order.id;
    this.documentService.getDocumentsByCatalog(catalogDetail, optionQueryCatalogGetDocuments).subscribe(resDocument => {
      const documents: any = resDocument;
      this.documentsData = documents.data;
      for (let i = 0; i < this.documentsData.length; i++) {
        const element = this.documentsData[i];
        this.documentService.downloadS3ById(element.id).subscribe(res => {
          if (res.type !== 'application/pdf') {
            this.blobToBase64(res).then(resBase64 => {
              this.imageDocuments.push({
                id: element.id,
                image: resBase64,
                select: false,
              });
            });
          } else {
            this.imageDocuments.push({
              id: element.id,
              image: '../../../../assets/images/field.png',
              select: false,
            });
          }
        });
      }
      if (documents.data.length !== 0) {
        this.multiplesDocumentsExist = true;
      } else {
        this.multiplesDocumentsExist = false;
      }
      this.loading = false;
      setTimeout(() => {
        this.loadComponentsMultiple();
      }, 100);
    });
  }
  selectImage(document: any) {
    const indexImage = this.imageDocuments.indexOf(document);
    if (this.imageDocuments[indexImage].select) {
      this.imageDocuments[indexImage].select = false;
    } else {
      this.imageDocuments[indexImage].select = true;
    }
  }
  getHashById(idDocument: string) {
    this.documentService.getDocumentById(idDocument).subscribe(res => {
      const name = res['nameDocument'];
      this.downloadS3ByHash(name, idDocument);
    });
  }
  downloadS3ByHash(name: string, idDocument: string) {
    this.documentService.downloadS3ById(idDocument).subscribe((res: any) => {
      const blob = new Blob([res], { type: 'application/octet-stream' });
      saveAs(blob, name);
    });
  }
  deleteField() {
    let activeImage = 0;
    for (let j = 0; j < this.imageDocuments.length; j++) {
      const image = this.imageDocuments[j];
      if (image.select) {
        activeImage++;
      }
    }
    let imageDeleteCount = 0;
    for (let i = 0; i < this.imageDocuments.length; i++) {
      const image = this.imageDocuments[i];
      if (image.select) {
        this.documentService.deleteDocument(image.id).subscribe(res => {
          imageDeleteCount++;
          if (imageDeleteCount === activeImage) {
            this.loadDocuments(this.currentCatalogDetail);
          }
        }, res => {
          this.toastDownService.showError('Error', 'No se pudo borrar los archivos');
        });
      }
    }
  }
  loadComponents() {
    this.dropArea = document.querySelector('#drag-drop-area');
    this.dragText = document.querySelector('#headerComponent');
    this.dragIcon = document.querySelector('#dragIcon');
    this.uploadButton = document.querySelector('#uploadButton');
    this.uploadInput = document.querySelector('#uploadInput');
  }
  loadComponentsMultiple() {
    this.dropArea = document.querySelector('#drag-drop-area-multiple');
    this.dragText = document.querySelector('#headerComponentMultiple');
    this.dragIcon = document.querySelector('#dragIconMultiple');
    this.uploadButton = document.querySelector('#uploadButtonMultiple');
    this.uploadInput = document.querySelector('#uploadInputMultipleDocs');
  }
  errorDocuments(error: string) {
    this.dragText.textContent = error;
    this.dragIcon.classList.add('pi-cloud-upload');
    this.dragIcon.classList.remove('pi-check');
    this.dropArea.classList.add('drag-area');
    this.dropArea.classList.remove('drag-area-success');
    this.captureFile = '';
  }
  documentReady() {
    this.dragText.textContent = 'Suelte el archivo para cargarlo';
    this.dragIcon.classList.remove('pi-cloud-upload');
    this.dragIcon.classList.add('pi-check');
    this.dropArea.classList.remove('drag-area');
    this.dropArea.classList.add('drag-area-success');
  }
  deleteDocument() {
    this.captureFile = '';
  }
  uploadDocument() {
    this.uploadInput.value = '';
    this.uploadInput.click();
  }
  addDocument(event: Event) {
    event.preventDefault();
    this.drop(event);
  }
  addDocumentMultiple(event: Event) {
    event.preventDefault();
    this.dropMultiple(event);
  }
  dropMultiple(event: any) {
    event.preventDefault();
    if (this.imageDocuments.length === 0) {
      this.documentReady();
    }
    this.fileNameUploadNow(event);
  }
  keepComponent(event: any) {
    event.preventDefault();
    if (this.imageDocuments.length === 0) {
      this.documentReady();
    }
  }
  leaveComponent(event: Event) {
    event.preventDefault();
    this.dragText.textContent = 'Arrastra tu documento aquí, o selecciona (Solo se permiten imagenes o archivos pdf de máximo 5mb)';
    this.dropArea.classList.remove('drag-area-success');
    this.dropArea.classList.add('drag-area');
  }
  clearComponent() {
    this.dragText.textContent = 'Arrastra tu documento aquí, o selecciona (Solo se permiten imagenes o archivos pdf de máximo 5mb)';
    this.dropArea.classList.remove('drag-area-success');
    this.dropArea.classList.add('drag-area');
  }
  saveDocument() { // se subio el documento de pago
    const currectFile = this.captureFile;
    const currentOrder = this.order;
    this.documentService.postDocument(currectFile, currentOrder.businessId, this.currentCatalogDetail,
      currentOrder.id).subscribe(resDocument => {
        const document: string = resDocument.identifiers[0].id;
        if (this.currentCatalogDetailCode === 'TRPG') {
          const newDocumentPurchaseOrder: IUpdateDocumentPurchaseOrder = {
            ...currentOrder,
            observation: OBSERVATION_PURCHASE_ORDER_PAY,
            documentPayId: document,
          };
          this.uploadPurchaseOrder(newDocumentPurchaseOrder, 'FACTURADO', currentOrder);
        }
      }, err => {
        this.toastDownService.showError('Error', 'No se pudo guardar el documento.');
      });
  }
  uploadPurchaseOrder(newPurchaseOrder: IUpdateDocumentPurchaseOrder, state: string, currentOrder: any) {
    this.purchaseOrderService.update(currentOrder.id, newPurchaseOrder)
      .subscribe(resPurchasOrder => {
        if (currentOrder.business.businessCredit) { // si la empresa tiene credito
          currentOrder.documentPayId = newPurchaseOrder.documentPayId;
        }
        this.changeState.emit([currentOrder, state]);
        this.toastDownService.showSuccess('Listo', 'Documento guardado exitosamente');
      }, err => {
        this.toastDownService.showError('Error', 'No se pudo guardar el documento');
      });
  }
  drop(event: any) {
    event.preventDefault();
    this.documentReady();
    this.fileName(event);
  }
  // event to catch photo
  async fileName(event: any) {
    if (this.validationSize(event) && this.validationExt(event)) {
      this.dragText.textContent = `Archivo cargado`;
      if (event.dataTransfer) {
        this.captureFile = event.dataTransfer.files[0];
      } else {
        this.captureFile = event.target.files[0];
      }
    }
  }
  // event to catch photo
  async fileNameUploadNow(event: any) {
    if (this.validationSize(event) && this.validationExt(event)) {
      if (this.imageDocuments.length === 0) {
        this.dragText.textContent = `Cargando...`;
      } else {
        document.querySelector('#headerWithDocuments').textContent = `Cargando...`;
      }
      if (event.dataTransfer) {
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
          const element = event.dataTransfer.files[i];
          if (i === event.dataTransfer.files.length - 1) {
            this.uploadFieldMultiple(element, true);
          } else {
            this.uploadFieldMultiple(element, false);
          }

        }
      } else {
        for (let i = 0; i < event.target.files.length; i++) {
          const element = event.target.files[i];
          if (i === event.target.files.length - 1) {
            this.uploadFieldMultiple(element, true);
          } else {
            this.uploadFieldMultiple(element, false);
          }
        }
      }
    }
  }
  uploadFieldMultiple(field: any, lastFile: boolean) {
    this.documentService.postDocument(field, this.businessService.business.id, this.currentCatalogDetail, this.order.id)
      .subscribe(resDocument => {
        if (lastFile) {
          this.loading = true;
          this.loadDocuments(this.currentCatalogDetail);
        }
      });
  }
  uploadField() {
    this.catalogDetailService.getAllCatalogDetails(this.optionQueryCatalogDocument).subscribe(resCatalogDetail => {
      const catalogDetail: any = resCatalogDetail;
      this.documentService.postDocument(this.captureFile, this.businessService.business.id, catalogDetail.data[0].id,
        this.order.id)
        .subscribe(resDocument => {
          this.loading = true;
          this.loadDocuments(this.currentCatalogDetail);
        });
    });
  }
  saveDocumentBDO() { // se subio el documento factura bdo
    const currectFile = this.captureFile;
    const order = this.order;
    const optionsQueryCatalog = this.optionQueryCatalogDocument;
    const invoiceNumber = this.invoiceForm.value.invoiceNumber;
    const facturationDate = this.invoiceForm.value.facturationDate;
    this.catalogDetailService.getAllCatalogDetails(optionsQueryCatalog).subscribe(resCatalogDetail => {
      const catalogDetail: any = resCatalogDetail;
      this.documentService.postDocument(currectFile, order.businessId, catalogDetail.data[0].id, order.id)
        .subscribe(resDocument => {
          order.observation = OBSERVATION_PURCHASE_ORDER_INVOCED_UPLOAD;
          order.documentInvoiceBdoId = resDocument.identifiers[0].id;
          order.numberInvoiceBdo = invoiceNumber;
          order.facturationDate = facturationDate;
          this.purchaseOrderService.update(order.id, order)
            .subscribe(resPurchasOrder => {
              this.changeState.emit([order, 'REVISION PAGO']);
              this.invoiceForm.reset();
              this.toastDownService.showSuccess('Listo', 'Documento guardado exitosamente');
            }, err => {
              this.toastDownService.showError('Error', 'No se pudo guardar el documento');
            });
        });
    });
  }
  validationSize(event: any): boolean {
    let fileInput: any;
    if (event.dataTransfer) {
      fileInput = event.dataTransfer;
    } else {
      fileInput = event.target;
    }
    for (let i = 0; i < fileInput.files.length; i++) {
      let archivo_upload = fileInput.files[i];
      archivo_upload = fileInput.files[i];
      if (archivo_upload.size > 5000000) {
        this.dragText.textContent = 'El archivo es muy grande, intente con otro archivo';
        this.dropArea.classList.remove('drag-area-success');
        this.dropArea.classList.add('drag-area');
        return false;
      }
    }
    return true;
  }
  validationExt(event: any): boolean {
    let fileInput: any;
    if (event.dataTransfer) {
      fileInput = event.dataTransfer;
    } else {
      fileInput = event.target;
    }
    for (let i = 0; i < fileInput.files.length; i++) {
      const filename = fileInput.files[i].name;
      const ext = filename.split('.').pop().toLowerCase();
      if (ext !== 'png' && ext !== 'jpg' && ext !== 'jpeg' && ext !== 'pdf') {
        this.dragText.textContent = 'La extensión del archivo no es válida, intente con otro archivo';
        this.dropArea.classList.remove('drag-area-success');
        this.dropArea.classList.add('drag-area');
        return false;
      }
    }
    return true;
  }
  validador() { }

  validTaxes() {
    const currentOrder = this.order;
    this.purchaseOrderService.validationDai(currentOrder.id).subscribe(res => {
      this.toastDownService.showSuccess('Listo', `Los documentos de impuesto de la orden ${currentOrder.secuence}
         han sido validados`);
      this.changeState.emit([currentOrder, 'Impuesto Aprobado']);
    }, (err) => {
      this.toastDownService.showError('Error', `Ocurrió un error al validar el pago ${err.error.description}`);
    });
  }
}

