<div class="container">

    <h1 id="title" class="title">Reestablecer Contraseña</h1>

    <div class="col-md-12" *ngIf='updateToken === null'>
        <label class="info">Ingrese su correo electrónico o nombre de usuario y a continuación
            siga las instrucciones que serán enviadas a su
            correo electrónico.</label>
        <form id="form" [formGroup]="formData" (ngSubmit)="ResetPassword()" novalidate>

            <label for="username" class="label">Nombre de usuario o email <span class="text-danger">*</span></label>
            <input id="username" formControlName="username" (click)="open(dialog)" type="text" class="form-control" pInputText />
            <div *ngIf="formData.controls['username'].invalid && (formData.controls['username'].dirty || formData.controls['username'].touched)"
                class="text-danger">
                <span *ngIf="this.formData.get('username').hasError('required')">
                    (El campo es obligatorio)
                </span>
            </div>
            <br />
             <button type="submit" class="btn-primary btn btn-block"  status="submit" [disabled]="!formData.valid || loading"
                      style="width: 100%;">
                      {{loading?'Enviando correo':'Reestablecer contraseña'}}</button>
            <span class="label-with-link">
                <a class="forgot-password caption-2 link" routerLink=""> Regresar</a>
            </span>
        </form>
    </div>
    <div class="col-md-12" *ngIf='updateToken !== null  '>

        <div id="texto-vd"></div>
        <br />

        <div>
            <form [formGroup]="formData" class="mx-auto" (ngSubmit)="ResetPassword()" novalidate>
                <div class="form-group">
                    <label>Contraseña</label>
                    <div class="input-group">
                        <!-- Change input type to be able to accept dynamic data -->
                        <input [type]="fieldTextType ? 'text' : 'password'" class="form-control"
                            formControlName="password" />
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <i class="fa" [ngClass]="{
                          'fa-eye-slash': !fieldTextType,
                          'fa-eye': fieldTextType
                        }" (click)="toggleFieldTextType()"></i>
                            </span>
                        </div>
                    </div>
                    <div *ngIf="formData.controls['password'].invalid && (formData.controls['password'].dirty ||
                    formData.controls['password'].touched)" class="text-danger">
                        <div *ngIf="this.formData.get('password').errors?.required">
                            (El campo contraseña es obligatorio)
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label>Repita su contraseña</label>
                    <div class="input-group">
                        <!-- Change input type to be able to accept dynamic data -->
                        <input [type]="repeatFieldTextType ? 'text' : 'password'" class="form-control"
                            formControlName="confirmPassword" />
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <i class="fa" [ngClass]="{
                          'fa-eye-slash': !repeatFieldTextType,
                          'fa-eye': repeatFieldTextType
                        }" (click)="toggleRepeatFieldTextType()"></i>
                            </span>
                        </div>
                    </div>
                    <div *ngIf="formData.controls['confirmPassword'].invalid && (formData.controls['confirmPassword'].dirty ||
                    formData.controls['confirmPassword'].touched)" class="text-danger">
                        <div *ngIf="this.formData.get('confirmPassword').errors?.required">
                            (El campo confirmar contraseña es obligatorio)
                        </div>
                    </div>
                </div>
                <br />
                <button class="btn-primary btn btn-block" type="submit" [disabled]="!formData.valid" status="info"
                    shape="round">
                    Registrar contraseña
                </button>
                <span class="label-with-link">
                    <a class="forgot-password caption-2 link" routerLink="">Regresar</a>
                </span>
            </form>

           
        </div>

    </div>
    <ng-template #dialog let-ref="dialogRef" class="col-md-12">
        <nb-card style=" display: grid; justify-content: center; text-align: center; ">
            <!-- <nb-card-header *ngIf="title"> {{title}}</nb-card-header> -->
            <nb-card-body><p>{{ description }}</p>
                <b>{{mail}}</b>
            </nb-card-body>
            <br />
            <nb-card-footer>
                <button nbButton (click)="ref.close()" routerLink="/">Aceptar</button>
            </nb-card-footer>
        </nb-card>
    </ng-template>
</div>