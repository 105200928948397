<!-- Button trigger modal -->
<button id="buttonModal" hidden type="button" class="btn btn-primary" data-toggle="modal"
    data-target=".multiple_docs_modal">
</button>
<button id="buttonModalInvoiceBdo" hidden type="button" class="btn btn-primary" data-toggle="modal"
    data-target=".bd-invoice-modal-lg">
</button>
<button id="buttonModalSingle" hidden type="button" class="btn btn-primary" data-toggle="modal"
    data-target=".bd-invoice-client-modal-lg">
</button>

<!-- Modal Multiples DOCS -->
<div class="modal fade multiple_docs_modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="pt-3 pb-3 pl-4 pr-4">
                <div *ngIf="loading" style="height: 35px;">
                    <div class="position-absolute" style="left: 50%;">
                        <div class="spinner-border text-primary text-center" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="multiplesDocumentsExist === true && !loading" id="drag-drop-area" class="drag-area-multiple"
                    (drop)="dropMultiple($event)" (dragover)="keepComponent($event)"
                    (dragleave)="leaveComponent($event)">
                    <div style="height: 335px; overflow-y: scroll;">
                        <header id="headerWithDocuments" class="text-center px-4">Gestiona los documentos de la orden
                        </header>
                        <div class="row mb-1 ml-2" style="height: 50px;">
                            <div *ngFor="let document of imageDocuments"
                                class="imagesCard card col-4 col-lg-2 mr-1 justify-content-center py-1"
                                style="overflow: hidden;">
                                <img src="{{document.image}}" (click)="selectImage(document)">
                                <button class="btn btn-outline-success mt-1"
                                    (click)="getHashById(document.id)">Descargar</button>
                                <em *ngIf="document.select" class="pi pi-check-circle mr-2 iconsSelective"
                                    style="color: green; "></em>
                            </div>
                        </div>
                    </div>
                    <input id="uploadInputMultipleDocs" type="file" hidden (change)="addDocumentMultiple($event)"
                        multiple>
                    <hr>
                    <div class="d-flex justify-content-end bd-highlight">
                        <button class="btn btn-outline-danger bd-highlight py-2 mr-3"
                            style="font-size: 12px;" (click)="deleteField()"><em class="pi pi-times mr-2 icons"
                                style="font-size: 12px;"></em>Borrar imagen</button>
                        <button *ngIf="!this.optionsAdmin" class="btn btn-outline-primary bd-highlight py-2 mr-3"
                            style="font-size: 12px;" (click)="uploadDocument()"><em class="pi pi-upload mr-2 icons"
                                style="font-size: 12px;"></em>Cargar imágen</button>
                        <button *ngIf="this.optionsAdmin" class="btn btn-outline-info bd-highlight py-2 mr-3"
                            style="font-size: 12px;" (click)="validTaxes()" data-dismiss="modal"><em class="pi pi-times mr-2 icons"
                                style="font-size: 12px;"></em>Validar impuestos</button>
                    </div>
                </div>
                <div *ngIf="multiplesDocumentsExist === false && !loading && !this.optionsAdmin" id="drag-drop-area-multiple"
                    class="drag-area mb-2 w-100 d-flex justify-content-center" (drop)="dropMultiple($event)"
                    (dragover)="keepComponent($event)" (dragleave)="leaveComponent($event)">
                    <div class="icon"><i id="dragIconMultiple" class="pi pi-cloud-upload"></i></div>
                    <header id="headerComponentMultiple" class="text-center px-4">Aún no existen docuemtos, arrastra tu
                        documento aquí, o selecciona (Solo
                        se permiten imagenes o archivos pdf de máximo 5mb)</header>
                    <button id="uploadButtonMultiple" (click)="uploadDocument()">Desde tu ordenador</button>
                    <input id="uploadInputMultipleDocs" type="file" hidden (change)="addDocumentMultiple($event)"
                        multiple>
                </div>
                <div *ngIf="multiplesDocumentsExist === false && !loading && this.optionsAdmin">
                    <div style="height: 335px; overflow-y: scroll;">
                        <h5>Aún no existen documentos.</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal Invoice -->
<div *ngIf="this.modalBDO" class="modal fade bd-invoice-modal-lg" tabindex="-1" role="dialog"
    aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="pt-3 pb-3 pl-4 pr-4" [formGroup]="invoiceForm">
                <div id="drag-drop-area" class="drag-area mb-2 w-100 d-flex justify-content-center"
                    (drop)="drop($event)" (dragover)="keepComponent($event)" (dragleave)="leaveComponent($event)">
                    <div class="icon"><i id="dragIcon" class="pi pi-cloud-upload"></i></div>
                    <header id="headerComponent" class="text-center px-4">Arrastra tu documento aquí, o selecciona (Solo
                        se permiten imagenes o archivos pdf de máximo 5mb)</header>
                    <button id="uploadButton" (click)="uploadDocument()">Desde tu ordenador</button>
                    <input id="uploadInput" type="file" hidden (change)="addDocument($event)">
                </div>
                <div>
                    <input type="text" class="form-control input-upload" id="invoiceNumber"
                        placeholder="Número de factura" formControlName="invoiceNumber" (change)="validador()"
                        autocomplete="off">
                    <span
                        *ngIf="invoiceForm.controls['invoiceNumber'].invalid && invoiceForm.controls['invoiceNumber'].touched"
                        class="text-danger validateText">
                        El número de la factura es incorrecto.
                    </span>
                </div>
                <br>
                <div class="calendar-upload">
                    <p-calendar [style]="{'width':'100%'}" fullWidth formControlName="facturationDate"
                        placeholder="Fecha facturación" dateFormat="yy-mm-dd">
                    </p-calendar>
                    <br>
                    <span
                        *ngIf="invoiceForm.controls['facturationDate'].invalid && invoiceForm.controls['facturationDate'].touched"
                        class="text-danger validateText">
                        Ingresar fecha de facturación.
                    </span>
                </div>
                <div class="mt-4 w-100 d-flex justify-content-end">
                    <button id="processContinue" class="btn btn-outline-primary bd-highlight py-2 mr-3"
                        style="font-size: 12px;" data-dismiss="modal" (click)="deleteDocument()"><em
                            class="pi pi-arrow-left mr-2 icons" style="font-size: 12px;"></em>Borrar imagen</button>
                    <button id="processContinue" class="btn btn-primary bd-highlight py-2" style="font-size: 12px;"
                        data-dismiss="modal" (click)="saveDocumentBDO()" [disabled]="!this.invoiceForm.valid"><em
                            class="pi pi-check-circle mr-2 icons" style="font-size: 12px;"></em>Guardar</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Document Modal -->
<div id="modalDocumentUpload" class="modal fade bd-invoice-client-modal-lg" tabindex="-1" role="dialog"
    aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="pt-3 pb-3 pl-4 pr-4">
                <div id="drag-drop-area" class="drag-area mb-2 w-100 d-flex justify-content-center"
                    (drop)="drop($event)" (dragover)="keepComponent($event)" (dragleave)="leaveComponent($event)">
                    <div class="icon"><i id="dragIcon" class="pi pi-cloud-upload"></i></div>
                    <header id="headerComponent" class="text-center px-4">Arrastra tu documento aquí, o selecciona (Solo
                        se permiten imagenes o archivos pdf de máximo 5mb)</header>
                    <button id="uploadButton" (click)="uploadDocument()">Desde tu ordenador</button>
                    <input id="uploadInput" type="file" hidden (change)="addDocument($event)">
                </div>
                <br>
                <h6 class="textSubtitleModal textIfnBdo">NOTA: Obligatorio colocar el correo: <strong>informacion@bdo.ec</strong>
                    en el momento de su transferencia, ya que si no coloca el mail indicado su despacho tomará más días hasta la revisión del pago.</h6>
           
                <div class="mt-4 w-100 d-flex justify-content-end">
                    <button id="processContinue" class="btn btn-outline-primary bd-highlight py-2 mr-3"
                        style="font-size: 12px;" data-dismiss="modal" (click)="deleteDocument()"><em
                            class="pi pi-arrow-left mr-2 icons" style="font-size: 12px;"></em>Borrar imagen</button>
                    <button id="processContinue" class="btn btn-primary bd-highlight py-2" style="font-size: 12px;"
                        data-dismiss="modal" (click)="saveDocument()"><em class="pi pi-check-circle mr-2 icons"
                            style="font-size: 12px;"></em>Guardar</button>
                </div>
            </div>
        </div>
    </div>
</div>