import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'app/@core/services/toast.service';
import { LoginForm } from '../models/login.form';
import { HttpClient } from '@angular/common/http';
import { config } from 'app/config';
import { LOCAL_IP } from 'app/shared/constants/shared-contants.constants';


@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  formData: FormGroup;
  formEmail: FormGroup;
  formRole: FormGroup;

  showPassword = false;
  validatedUser = false;
  loading = false;
  loadingSend = false;
  sendEmail = false;
  displayWallet = false;
  noRole = false;
  fieldTextType;
  repeatFieldTextType;
  constructor(
    private authService: AuthService,
    private router: Router,
    private toast: ToastService,
    private _loginForm: LoginForm,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {

    this.getIp();
    this.formData = this._loginForm.getForm();
    this.isAuth();

    this.formEmail = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
    // Minúsculas y sin espacios

    this.formData.get('username').valueChanges.subscribe((event) => {
      let text = event.toLowerCase() as string;
      if (text)
        text = this.trimText(text);
      this.formData.get('username').setValue(text, { emitEvent: false });
    });
    this.formEmail.get('email').valueChanges.subscribe((event) => {
      let text = event.toLowerCase() as string;
      if (text)
        text = this.trimText(text);
      this.formEmail.get('email').setValue(text, { emitEvent: false });
    });
  }

  trimText(text: string) {
    try {
      const arrayText = text.split(' ');
      if (arrayText && arrayText.length > 1) {
        let finalText = '';
        arrayText.forEach(t => {
          finalText += t.trim();
        });
        return finalText;
      } else {
        return text.trim();
      }
    } catch (error) {
      return text;
    }
  }


  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
  private getFormData(object): URLSearchParams {
    const body = new URLSearchParams();
    Object.keys(object).forEach(key => { body.set(key, object[key]); });
    return body;
  }

  public async login() {
    this.markFormGroupTouched(this.formData);
    if (this.formData.valid) {
      this.loading = true;
      this.authService.login(this.formData.value).subscribe((data: any) => {
        localStorage.clear();
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('refresh_token', data.refresh_token);
        this.goPage();
      }, error => {
        // if (error.error.error_description === 'Invalid user credentials') {
        //   this.toast.showError('Alerta', 'Usuario o contraseña incorrecta');
        // }
        this.toast.showError('Usuario o contraseña incorrecta', '');
      });
    } else {
      this.toast.showWarning('Advertencia', 'Los datos del formulario no son válidos');
      return;
    }
  }

  async getIp() {
    let ip;
    try {
      const dataIp = await this.http.get(config.urlIp).toPromise();
      ip = dataIp['ip'] ? dataIp['ip'] : 'no caputure ip';
    } catch (error) {
      ip = 'error capture ip';
    }
    localStorage.setItem(LOCAL_IP, ip);
  }

  goPage() {
    this.loading = false;
    this.activatedRoute.queryParams.subscribe(({ redirect }) => {
      const isAdmin = this.authService.isExistRole('admin');
      const isMainAdmin = this.authService.isExistRole('ADMIN_PRINCIPAL');
      const isSecondAdmin = this.authService.isExistRole('ADMIN_SECONDARY');
      const isBending = this.authService.isExistRole('BREEDING');
      const isFattening = this.authService.isExistRole('FATTENING');
      const isOverView = this.authService.isExistRole('OVERVIEW');
      const isFinalProduct = this.authService.isExistRole('PRODUCT_FINAL');
      const isRawMaterial = this.authService.isExistRole('RAW_MATERIAL');
      if ((isAdmin || isMainAdmin || isRawMaterial || isBending || isFattening ||
        isFinalProduct || isOverView || isSecondAdmin || isFinalProduct) && redirect) {
        const urlParts = redirect.split('?') as any[];
        if (urlParts.length > 0 && urlParts[1]) {
          const queries = urlParts[1].split('=');
          const queryParams = {};
          for (let index = 0; index < queries.length; index++) {
            if (index % 2 === 0) {
              const element = queries[index];
              queryParams[element] = queries[index + 1].replace('%20', ' ');
            }
          }
          queryParams['path'] = 'true';
          this.router.navigate([urlParts[0]], { queryParams });
        } else {
          const queryParams = { path: 'true' };
          this.router.navigate([redirect], { queryParams });
        }
      } else {
        if (isAdmin || isMainAdmin) {
          const queryParams = { path: 'true' };
          this.router.navigate(['/app/dashboard']);
        }
      }
    });
  }



  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleRepeatFieldTextType() {
    this.repeatFieldTextType = !this.repeatFieldTextType;
  }

  /**
   * Valida si ya inicio sesión
   */
  isAuth() {
    if (localStorage.getItem('access_token')) {
      this.goPage();
    }
  }
}
