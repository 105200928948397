<div
	class="content-alert fullscreen vertical-align"
	*ngIf="alertService.data"
	[@openClose]="alertService.data && alertService.data.show ? 'open' : 'closed'"
>
	<div class="content text-center">
		<div class="fs-26 f-bold">{{ alertService.data.title }}</div>
		<div>{{ alertService.data.subtitle }}</div>
		<div *ngIf="alertService.data.icon">
			<em *ngIf="alertService.data.type === 0" class="bi text-primary" [class]="alertService.data.icon"></em>
			<em *ngIf="alertService.data.type === 1" class="bi text-warning" [class]="alertService.data.icon"></em>
			<em *ngIf="alertService.data.type === 2" class="bi text-danger" [class]="alertService.data.icon"></em>
		</div>
		<div class="text-muted">{{ alertService.data.message }}</div>
		<br />
		<div class="row">
			<div class="col" *ngIf="alertService.data.cancelButtonText">
				<button (click)="close(false)" class="br-10 btn btn-secondary">
					{{ alertService.data.cancelButtonText }}
				</button>
			</div>
			<div class="col">
				<button
					(click)="close(true)"
					class="br-10 btn"
					[ngClass]="{
						'btn-outline-primary': alertService.data.type === 0,
						'btn-outline-warning': alertService.data.type === 1,
						'btn-outline-danger': alertService.data.type === 2
					}"
				>
					{{ alertService.data.confirmButtonText }}
				</button>
			</div>
		</div>
	</div>
</div>
