<router-outlet>
  <app-toast></app-toast>
  <app-alert></app-alert>
  <p-confirmDialog #cdinactivity [message]="messageInactivity" icon="pi pi-exclamation-triangle" [baseZIndex]="10000">
    <br>
    <p-footer>
      <button nbButton status="black" outline size="small" shape="round" class=" btn btn-secondary p-mr-2 p-mb-2"
        style="background-color:#AEBEC2 ; color: white; border: '1px solid #AEBEC2'" (click)="cdinactivity.reject()">
        <nb-icon icon="close-outline"></nb-icon>No {{showTime}}
      </button>
      <button nbButton status="#007AD9" size="small" shape="round" class="p-button  p-mr-2 p-mb-2"
        (click)="cdinactivity.accept()">
        <nb-icon icon="checkmark-outline"></nb-icon>Sí
      </button>
    </p-footer>
  </p-confirmDialog>

</router-outlet>
