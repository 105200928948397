import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SAFETRACK_PRIVATE_KEY } from 'app/shared/constants/safetrack.constants';

@Component({
  selector: 'ngx-private-key-form',
  templateUrl: './private-key-form.component.html',
  styleUrls: ['./private-key-form.component.scss'],
})
export class PrivateKeyFormComponent implements OnInit {

  formKey: FormGroup;

  @Input() visible: boolean;
  @Output() signAndSave = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.formKey = this.fb.group({
      privateKey: [null, Validators.required],
    });
  }

  onSavePrivateKey() {
    localStorage.setItem(SAFETRACK_PRIVATE_KEY, this.formKey.value.privateKey);
    this.visible = false;
    this.signAndSave.emit(true);
  }

  onCancel() {
    this.visible = false;
    this.signAndSave.emit(false);
  }
}
