<div class="container">
    <form [formGroup]="form" (ngSubmit)="onValidateRange()" aria-labelledby="title">
        <h2>Contador de etiquetas</h2>
        <div class="row" style="margin-top: 3rem;">
            <div class="form-group col-6">
                <label class="label-basic left-margin-label-content" for="input-businessId">Etiqueta
                    inicial<span class="text-danger">*</span></label>
                <input pInputText fullWidth formControlName="uniqueMark" placeholder="Ingrese etiqueta inicial"
                    id="input-label" class="form-control left-margin-input-content" type="text" maxlength="60">
                <div *ngIf="form.controls['uniqueMark']?.invalid && (form.controls['uniqueMark'].dirty ||
    form.controls['uniqueMark'].touched)" class="text-danger left-margin-message-content">
                    <div *ngIf="form.controls['uniqueMark'].errors.required">
                        Etiqueta inicial es obligatorio.
                    </div>
                    <div *ngIf="form.controls['uniqueMark'].errors.description">
                        Etiqueta inicial incorrecta
                    </div>
                </div>
            </div>
            <div class="form-group col-6">
                <label class="label-basic left-margin-label-content" for="input-businessId">Etiqueta final<span
                        class="text-danger">*</span></label>
                <input pInputText fullWidth formControlName="uniqueMarkEnd" placeholder="Ingrese etiqueta final"
                    id="input-label" class="form-control left-margin-input-content" type="text" maxlength="60">
                <div *ngIf="form.controls['uniqueMarkEnd'].invalid && (form.controls['uniqueMarkEnd'].dirty ||
    form.controls['uniqueMarkEnd'].touched)" class="text-danger left-margin-message-content">
                    <div *ngIf="form.controls['uniqueMarkEnd'].errors.required">
                        Etiqueta final es obligatorio.
                    </div>
                    <div *ngIf="form.controls['uniqueMarkEnd'].errors.description">
                        Etiqueta  final incorrecta
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="row">
        <div class="col-12">
            <button nbButton type="submit" shape="round" style="width: 100%;" status="info"
                class="p-button  p-mr-2 p-mb-2 modal-button-size" (click)="onValidateRange()"
                [disabled]="loading || form.invalid">
                <nb-icon icon="checkmark-circle-outline"></nb-icon>
                {{loading?'Contando...':'Contar etiquetas'}}
            </button>
        </div>
    </div>
    <h3 style="margin-top: 1.3rem;">
        Total etiquetas: <span *ngIf="total" class="badge badge-info"> {{ total }}</span></h3>
    <div class="row" style="margin-top: 2rem;">
        <div class="col-12 col-md-6" style="margin-top: 1rem;">
            <button nbButton status="info" outline type="button" size="small" shape="round"
                class="btn-primary btn btn-block" [disabled]="loading" [routerLink]="['../']">
                <nb-icon icon="arrow-back-outline"></nb-icon>
                Regresar
            </button>
        </div>
        <div class="col-12 col-md-6" style="margin-top: 1rem;">
            <button nbButton class="btn-primary btn btn-block" size="small" status="danger" shape="round"
                style="width: 100%;" (click)="clear()" [disabled]="loading">
                <nb-icon icon="trash-2-outline"></nb-icon>
                Limpiar Todo
            </button>
        </div>
    </div>
    <nb-accordion style="margin-top: 2rem;">
        <nb-accordion-item>
            <nb-accordion-item-header>
                <h5>Etiquetas consultadas</h5>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
                <table class="table table-hover" style="height: 100px;">
                    <thead>
                        <tr>
                            <th scope="col">Etiqueta Inicial</th>
                            <th scope="col">Etiqueta Final</th>
                            <th scope="col">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of listUniqueMark; let i = index" [attr.data-index]="i"
                            [style]="i==0?'font-weight: bold;':''">
                            <td>{{item.uniqueMarkStar}}</td>
                            <td>{{item.uniqueMarkEnd}}</td>
                            <td>{{item.total}}</td>
                        </tr>
                    </tbody>
                </table>
            </nb-accordion-item-body>
        </nb-accordion-item>
    </nb-accordion>
</div>