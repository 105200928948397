import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-network-explorer',
  templateUrl: './network-explorer.component.html',
  styleUrls: ['./network-explorer.component.scss'],
})
export class NetworkExplorerComponent implements OnInit {

  @Input() txnNetwork: string;
  @Input() networkExplorer: string;
  @Input() did: string;
  @Input() batch: string;
  @Input() id: string;
  @Input() isOut?: boolean;

  txSubstrStart: string;
  txSubstrEnd: string;
  didStart: string;
  didEnd: string;

  constructor() { }

  ngOnInit(): void {
    if (this.txnNetwork != null || this.networkExplorer != null) {
      this.txSubstrStart = this.txnNetwork.substr(0, 7);
      this.txSubstrEnd = this.txnNetwork.substr(-7);
    }
    if (this.did != null ) {
      this.didStart = this.did.substr(0, 23);
      this.didEnd = this.did.substr(-7);
    }
  }
}
