import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IUser } from 'app/shared/models/user.model';
import { LoadDataComponent } from 'app/shared/classes/load-data.component';
import { UserService } from 'app/shared/services/user.service';
import { SelectItem } from 'primeng/api';
import { ToastService } from 'app/@core/services/toast.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessService } from 'app/shared/services/business.service';
import { UserRole } from 'app/shared/enums/user-role.enum';

@Component({
  selector: 'ngx-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent extends LoadDataComponent implements OnInit {
  users: IUser[];
  public FIELD_SEARCH = 'username';
  public userSearch: string;
  userSearchUpdate = new Subject<string>();
  statusOptions: SelectItem[];
  status: boolean;
  show: boolean;
  public listbusiness: any[];
  public business: any;

  @Input() businessId = new EventEmitter<string>();
  constructor(
    private userService: UserService,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private businessService: BusinessService,
  ) {
    super();
    // Debounce search.
    this.userSearchUpdate
      .pipe(debounceTime(this.allDebounceTime), distinctUntilChanged())
      .subscribe((value) => {
        this.onSearch(value);
      });
  }

  ngOnInit(): void {
    if (typeof (this.businessId) !== 'string') {
      this.show = false;
    } else {
      this.show = true;
    }
    this.activatedRoute.queryParams.subscribe((optionQuery) => {
      this.optionQuery = {
        limit: optionQuery.limit ? optionQuery.limit : this.limitTable,
        offset: optionQuery.offset ? optionQuery.offset : 1,
        filterField: this.FIELD_SEARCH,
        filterValue: optionQuery.filterValue,
        sortField: optionQuery.sortField,
        sortDirection: optionQuery.sortDirection,
        boolField: optionQuery.boolField,
        boolValue: optionQuery.boolValue,
      };
    });
    this.loadData();
    this.sortOptions = [
      { label: 'Usuario A-Z', value: 'username' },
      { label: 'Usuario Z-A', value: '!username' },
    ];
    this.statusOptions = [
      { label: 'Usuarios activos', value: true },
      { label: 'Usuarios inactivos', value: false },
    ];
  }

  loadData() {
    this.router.navigate(['.'], {
      relativeTo: this.activatedRoute,
      queryParams: this.optionQuery,
    });
    this.isLoaded = false;
    if (typeof (this.businessId) !== 'string') {
      this.userService.getAll(this.optionQuery, UserRole.RELATIONS_BUSINESS).subscribe((res) => {
        this.users = res['data'];
        this.limitTable = res['limit'];
        this.totalRecords = res['total'];
        this.isLoaded = true;
      });
    } else {
      this.userService.getAllByBusiness(this.optionQuery, this.businessId,
        UserRole.RELATIONS_BUSINESS).subscribe((res) => {
        this.users = res['data'];
        this.limitTable = res['limit'];
        this.totalRecords = res['total'];
        this.isLoaded = true;
      });
    }
  }

  onSearch(value) {
    this.search(value);
    this.loadData();
  }

  onPaginate(event) {
    this.paginate(event);
    this.loadData();
  }

  onSortChange(event) {
    this.sortChange(event);
    this.loadData();
  }

  updateStatus(id: string) {
    this.isLoaded = false;
    this.userService.delete(id).subscribe((res) => {
      this.isLoaded = true;
      this.loadData();
      this.toastService.showSuccess('Se ha desactivado el estado del usuario',
        res['message'],
      );
    },
      (err) => {
        this.isLoaded = true;
        if (err.error.message)
          this.toastService.showWarning('Atención', err.error.message);
        else
          this.toastService.showWarning('Atención', 'No se ha cambiado el estado',
          );
      },
    );
  }

  updateStatusActive(data: string) {
    this.isLoaded = false;
    this.userService.updateStatus(data).subscribe((res: any) => {
      this.isLoaded = true;
      this.loadData();
      this.toastService.showSuccess('Se ha activado el estado del usuario',
        res['message'],
      );
    },
      (err) => {
        this.isLoaded = true;
        if (err.error.message)
          this.toastService.showWarning('Atención', err.error.message);
        else
          this.toastService.showWarning('Atención', 'No se ha cambiado el estado',
          );
      },
    );
  }

  handleChange(e, data: string) {
    if (e.checked === true) {
      this.updateStatusActive(data);
    } else {
      this.updateStatus(data);
    }
  }

  onSelect() { }

  onStatusChange(event) {
    this.searchStatus(event);
    this.loadData();
  }
}
