<div id="accordionExample">
    <div class="card cardCollapse">
        <div id="accordion" style="position: absolute; top: 20px; right: 20px;" data-toggle="collapse" data-target="#collapseOne">
            <em class="pi pi-chevron-down btn-disabled"></em>
        </div>
        <div id="headingOne" class="d-flex pt-3 px-5" style="cursor: pointer;" data-toggle="collapse"
            data-target="#collapseOne">
            <p class="textBodyTitles">DATOS DE FACTURACIÓN</p>
        </div>
        <form id="collapseOne" class="collapse px-5 mb-4" aria-labelledby="headingOne"
            data-parent="#accordionExample" [formGroup]="invoiceForm">
            <div class="row mb-2">
                <div class="col-md-6 mb-2 collapse show">
                    <p class="textSubtitle mb-0">Empresa</p>
                    <input id="businessName" class="form-control bg-transparent" readonly type="text">
                </div>
                <div class="col-md-3 collapse show">
                    <p class="textSubtitle mb-0">RUC</p>
                    <input id="businessRuc" class="form-control bg-transparent" readonly type="text">
                </div>
                <div class="col-md-3 mb-2  collapse show">
                    <p class="textSubtitle mb-0">Teléfono</p>
                    <input class="form-control bg-transparent" formControlName="phone" type="text"
                        (change)="sendInformation()" tabindex="6">
                    <span *ngIf="invoiceForm.controls['phone'].invalid && (invoiceForm.controls['phone'].dirty ||
                                            invoiceForm.controls['phone'].touched)" class="text-danger validateText">
                        El teléfono es obligatorio.
                    </span>
                </div>
                <div class="col-md-6 mb-2  collapse show">
                    <p class="textSubtitle mb-0">Dirección</p>
                    <input class="form-control bg-transparent" formControlName="direction" type="text"
                        (change)="sendInformation()" tabindex="1">
                    <span *ngIf="invoiceForm.controls['direction'].invalid && (invoiceForm.controls['direction'].dirty ||
                            invoiceForm.controls['direction'].touched)" class="text-danger validateText">
                        La dirección es obligatoria.
                    </span>
                </div>
                <div class="col-md-6 mb-2  collapse show">
                    <p class="textSubtitle mb-0">Mail</p>
                    <input class="form-control bg-transparent" formControlName="email" type="text"
                        (change)="sendInformation()" tabindex="5">
                    <span *ngIf="invoiceForm.controls['email'].invalid && (invoiceForm.controls['email'].dirty ||
                                                            invoiceForm.controls['email'].touched)"
                        class="text-danger validateText">
                        El correo es obligatorio.
                    </span>

                </div>
            </div>
        </form>
    </div>
</div>