import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from 'app/@core/services/toast.service';
import { AuthService } from '../../services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
@Component({
  selector: 'ngx-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {

  public formData: FormGroup;
  public isLoaded: boolean;
  registrationForm: FormGroup;
  fieldTextType: boolean;
  repeatFieldTextType: boolean;
  public updateToken: any = null;
  public uri = window.location.href;
  public userid: string;
  public modal: any;
  public description: string = '';
  public mail: string = '';
  loading = false;

  constructor(private dialogService: NbDialogService,
    private authService: AuthService,
    private router: Router,
    private toast: ToastService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.initRegForm();

  }

  initRegForm() {

    if (this.uri.includes('token')) {
      this.updateToken  = (this.uri.split('token=')[1]).split('&id=')[0];
      this.userid = this.uri.split('&id=')[1];

      this.formData = this.fb.group({
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      });
    } else {
      this.formData = this.fb.group({
        username: ['', Validators.required],
      });
    }
  }

  public ResetPassword() {
    this.loading = true;
    let datos: any;
    if (this.formData.value.username) {
      if (this.formData.value.username.includes('@')) {
        datos = { email: this.formData.value.username };
      } else {
        datos = { username: this.formData.value.username };
      }
    } else {
      datos = this.formData.value;
      datos.username = this.userid;
    }
    if (this.updateToken === null) {
      this.authService.resetPassword(datos).subscribe((res) => {
        this.description = res['message'];
        this.mail = res['mail'];
        this.loading = true;
        this.dialogService.open(this.modal);
      }, err => {
        this.toast.showError('Error', err.error.description);
        this.loading = false;
      });
    } else {
      datos.token = this.updateToken;
      this.authService.resetPassword(datos).subscribe(() => {
        this.toast.showPrimary('Correcto', 'Se ha cambiado la contraseña correctamente');
          setTimeout(() => {
            this.router.navigate(['/auth']);
          }, 3000);
      }, err => {
        this.toast.showError('Error', err.error.description);
      });
    }
  }

  open(dialog: TemplateRef<any> ) {
    this.modal = dialog;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleRepeatFieldTextType() {
    this.repeatFieldTextType = !this.repeatFieldTextType;
  }

}
