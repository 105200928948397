<div class="card mb-2" [ngClass]="this.order.status.name == 'ANULADO' ? 'bg-light': ''">
    <div class="card-body">
        <div class="row text-left">
            <div class="col-lg-1 col-xl-1 text-left">
                <h6>Nro. {{value}}</h6>
            </div>
            <div class="col-lg-3 col-xl-3 text-left"
                [ngStyle]="{'margin-bottom': this.status == 'ANULADO' ? '25px': '0px'}">
                <ngx-status-bar [purchaseOrder]="this.order" [order]="this.order.status" [ordersTotal]="this.ordersTotal" [status]="this.status">
                </ngx-status-bar>
            </div>
            <div class="col-lg-3 col-xl-3 ml-2 ml-md-0">
                <div class="row">
                    <em class="pi pi-briefcase important-text"></em>
                    <p class="pl-1 mb-1 important-text">Empresa:&nbsp;</p>
                    <p class="pl-1 mb-2">{{this.order.business.name | slice:0:30}}</p>
                </div>
                <div class="row">
                    <em class="pi pi-calendar titles-orders"></em>
                    <p class="titles-orders pl-1 mb-2">Creacion:&nbsp;</p>
                    <p class="pl-1 mb-2">{{creationDate | date: PIPE_DATE}}</p>
                </div>
                <div class="row">
                    <em class="pi pi-dollar titles-orders"></em>
                    <p class="titles-orders pl-1 mb-1">Valor:&nbsp;</p>
                    <p class="pl-1 mb-2">{{costValue | number : '1.2-2'}}</p>
                </div>
                <div class="row" *ngIf="this.order.status.code >= '2' && this.order.business.businessCredit
                    && this.order.status.code != '99' && this.order.expirationDate && !this.order.paymentDate && !this.order.validPay">
                    <em class="pi pi-calendar danger-text"></em>
                    <p class="danger-text pl-1 mb-1">Pago límite:&nbsp;</p>
                    <p class="pl-1 mb-2">{{this.order.expirationDate | date: PIPE_DATE}}</p>
                </div>
                <div class="row" *ngIf="this.order.status.code >= '2' && this.order.paymentDate
                    && this.order.status.code != '99' && this.order.validPay && !this.order.documentPayId && this.order.business.isAsociate && this.order.business.businessCredit">
                    <em class="pi pi-check-square success-text"></em>
                    <p class="success-text pl-1 mb-1">Pago ext. aprobado:&nbsp;</p>
                    <p class="pl-1 mb-2">{{this.order.paymentDate | date: PIPE_DATE}}</p>
                </div>
                <div class="row" *ngIf="this.order.status.code >= '2' && this.order.paymentDate
                    && this.order.status.code != '99' && this.order.validPay && this.order.documentPayId">
                    <em class="pi pi-check-square success-text"></em>
                    <p class="success-text pl-1 mb-1">Pago aprobado:&nbsp;</p>
                    <p class="pl-1 mb-2">{{this.order.paymentDate | date: PIPE_DATE}}</p>
                </div>
            </div>
            <div class="col-lg-3 col-xl-3 ml-2 ml-md-0">
                <div *ngIf="this.order.status.code == '6'" class="row">
                    <em class="pi pi-home important-text"></em>
                    <p class="titles pl-1 mb-2 important-text">Enviado por:&nbsp;</p>
                    <p class="pl-1 mb-2">{{this.order.shipmentAccount}}</p>
                </div>
                <div *ngIf="this.order.status.code == '6'" class="row">
                    <em class="pi pi-id-card titles-orders"></em>
                    <p class="pl-1 mb-1 titles-orders">N. Guía:&nbsp;</p>
                    <p *ngIf="this.order.shipmentAccount == 'SERVIENTREGA'" class="pl-1 mb-2"><a [href]="currentGuideNumber" target="_blank">{{this.order.guideNumber | slice:0:30}}</a></p>
                    <p *ngIf="this.order.shipmentAccount != 'SERVIENTREGA'" class="pl-1 mb-2"><a>{{this.order.guideNumber | slice:0:30}}</a></p>
                </div>
                <div *ngIf="this.order.status.code == '6' && this.order.shipDateSismode"  class="row">
                    <em class="pi pi-calendar titles-orders"></em>
                    <p class="pl-1 mb-1 titles-orders">Despachado:&nbsp;</p>
                    <p class="pl-1 mb-2">{{this.order.shipDateSismode | date: PIPE_DATE}}</p>
                </div>
                <div *ngIf="this.order.status.code == '5' && this.optionsAdmin" class="row">
                    <em class="pi pi-ticket titles-orders"></em>
                    <p class="titles-orders pl-1 mb-2">N. interno:&nbsp;</p>
                    <p class="pl-1 mb-2">{{this.order.numberErpSismode}}</p>
                </div>
            </div>
            <div class="col-lg-2 col-xl-2 p-0 ml-2 ml-md-0 mt-1 mt-md-0 text-left text-lg-right">
                <button type="button" [routerLink]="['../',this.order.id,'view']"
                    class="btn btn-light px-2 py-1 mx-1 border width-botton botton-color mb-1" title="Ver detalles de la orden de compra">
                    <em class="pi pi-align-justify mr-1 font-weight-bold" style="font-size: 13px;"></em>&nbsp;Detalles
                </button>
                <!-- Admin Options -->

                <!-- Upload invoced -->
                <button *ngIf="this.optionsAdmin && this.order.status.code == '1' && this.order.status.code != '99'" (click)="uploadInvoice()"
                    type="button" class="btn btn-light px-2 py-1 mx-1 border width-botton botton-color mb-1" title="Subir factura del cliente">
                    <em class="pi pi-upload mr-1 font-weight-bold" style="font-size: 13px;"></em>&nbsp;Subir factura
                </button>

                <!-- Validate pay document -->
                <button *ngIf="this.optionsAdmin && this.order.documentPayId && !this.order.validPay && this.order.status.code != '99' && this.order.business.businessCredit"
                    (click)="openModalValidatePay()" type="button" title="Validar documento de pago de la orden"
                    class="btn btn-outline-info px-2 py-1 mx-1 width-botton mb-1">
                    <em class="pi pi-check-square mr-1 font-weight-bold" style="font-size: 13px;"></em>&nbsp;Validar PAY
                </button>
                <button *ngIf="this.optionsAdmin && this.order.documentPayId && !this.order.validPay && this.order.status.code == '3' && this.order.status.code != '99' && !this.order.business.businessCredit"
                    (click)="openModalValidatePay()" type="button" title="Validar documento de pago de la orden"
                    class="btn btn-outline-info px-2 py-1 mx-1 width-botton mb-1">
                    <em class="pi pi-check-square mr-1 font-weight-bold" style="font-size: 13px;"></em>&nbsp;Validar PAY
                </button>
                <!-- Validate pay external -->
                <button
                    *ngIf="this.optionsAdmin && !this.order.documentPayId && !this.order.validPay && this.order.status.code > '3' && this.order.status.code != '99' && this.order.business.businessCredit && this.order.business.isAsociate"
                    (click)="openModalValidatePayExternal()" type="button" title="Validar pago externo de la orden"
                    class="btn btn-outline-info px-2 py-1 mx-1 width-botton mb-1">
                    <em class="pi pi-check-square mr-1 font-weight-bold" style="font-size: 13px;"></em>&nbsp;Validar PAY externo
                </button>
                <!-- Send order to sismode -->
                <button *ngIf="this.optionsAdmin && this.order.status.code == '4' && this.order.status.code != '99'" type="button" data-toggle="modal"
                 data-target="#confirmationValidModalSismode" class="btn btn-light px-2 py-1 mx-1 border width-botton botton-color mb-1"
                    title="Enviar pedidos a sismode" (click)="uploadSismode()">
                    <em class="pi pi-cloud-upload mr-1 font-weight-bold" style="font-size: 13px;"></em>&nbsp;Enviar pedido a sismode
                </button>

                <!-- Validate tax document -->
                <button *ngIf="this.optionsAdmin && !this.order.validTax && this.order.business.businessType == 1 && this.order.status.code != '99'"
                    (click)="openModalValidateDai()" type="button" title="Validar documento de impuesto"
                    class="btn btn-outline-warning px-2 py-1 mx-1 width-botton mb-1">
                    <em class="pi pi-check-circle mr-1 font-weight-bold" style="font-size: 13px;"></em>&nbsp;Validar DAI
                </button>

                <!-- Descargar comprobante de Pago -->
                <button *ngIf="this.optionsAdmin && this.order.documentPayId && this.order.validPay  && this.order.status.code != '99'"
                    (click)="downloadValidPay()" type="button" class="btn btn-outline-primary px-2 py-1 mx-1 width-botton mb-1" title="Descargar Comp. Pago">
                    <em class="pi pi-download mr-1 font-weight-bold" style="font-size: 13px;"></em>&nbsp;Comp. Pago
                </button>

                <!-- Finish admin options -->

                <!-- Client options -->

                <!-- Upload pay document -->
                <button id="payDocument" *ngIf="this.order.status.code == '2' && !this.optionsAdmin && this.order.status.code != '99'"
                    (click)="uploadDocument()" type="button"
                    class="btn btn-outline-success px-2 py-1 mx-1 width-botton mb-1" title="Subir documento de pago">
                    <em class="pi pi-dollar mr-1 font-weight-bold" style="font-size: 13px;"></em>&nbsp;Pagar
                </button>
                <button id="payDocument" *ngIf="this.order.status.code >= '2' && !this.optionsAdmin && this.business.businessCredit
                     && (!this.order.validPay || this.order.validPay === null) && !this.order.documentPayId && this.order.status.code != '99'"
                    (click)="uploadDocument()" type="button"
                    class="btn btn-outline-success px-2 py-1 mx-1 width-botton mb-1" title="Subir documento de pago">
                    <em class="pi pi-dollar mr-1 font-weight-bold" style="font-size: 13px;"></em>&nbsp;Pagar
                </button>
                <button id="payDocumentCredit" *ngIf="!this.optionsAdmin && this.order.documentInvoiceBdo && this.order.status.code != '99'" (click)="downloadDocumentCredit()"
                    type="button" class="btn btn-outline-primary px-2 py-1 mx-1 width-botton mb-1" title="Descargar Factura BDO">
                    <em class="pi pi-download mr-1 font-weight-bold" style="font-size: 13px;"></em>&nbsp;Factura BDO
                </button>
                <button id="taxDocument" *ngIf="!this.optionsAdmin && !this.order.validTax && this.business.businessType == 1
                        && this.order.status.code != '99'" (click)="uploadTax()"
                         type="button" class="btn btn-light px-2 py-1 mx-1 border width-botton botton-color mb-1" title="Subir documento de impuesto">
                    <em class="pi pi-file mr-1 font-weight-bold" style="font-size: 13px;"></em>&nbsp;Subir impuesto
                </button>
                <button *ngIf="this.order.status.code <= '4' && this.order.status.code != '99'" type="button" data-toggle="modal" data-target="#confirmationValidModal"
                 (click)="cancelOrder()" class="btn btn-light px-2 py-1 mx-1 border width-botton botton-color mb-1"
                    title="Anular orden de compra">
                    <em class="pi pi-times-circle mr-1 font-weight-bold" style="font-size: 13px;"></em>&nbsp;Anular orden
                </button>
                <!-- Finish client options -->
            </div>
        </div>
    </div>
</div>