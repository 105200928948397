import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OptionQuery } from '../models/option-query.model';
import { config } from 'app/config';
import { IProductLabel } from '../models/product-labels.model';
import { IValidationRange } from '../models/unique.marks.mode';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';

const BASE_URL = `${config.api.url}${config.uniqueMark.mainEndpoint}`;
const BASE_URL_LOTS = `${config.api.url}${config.lotsUniqueMark.mainEndpoint}`;
const BASE_URL_PUBLIC = `${config.api.url}${config.uniqueMarkPublic.mainEndpoint}`;


@Injectable({
  providedIn: 'root',
})
export class UniqueMarkService implements AsyncValidator {

  constructor(
    protected http: HttpClient,
  ) { }


  activateIndividual(data: IProductLabel) {
    return this.http.put(`${BASE_URL}${config.uniqueMark.activate}${config.uniqueMark.individual}`, data);
  }
  activateRange(data: IProductLabel) {
    return this.http.put(`${BASE_URL}${config.uniqueMark.activate}${config.uniqueMark.range}`, data);
  }

  activateBatch(data: IProductLabel) {
    return this.http.put(`${BASE_URL}${config.uniqueMark.activate}${config.uniqueMark.batch}`, data);
  }

  disableIndividual(data: IProductLabel) {
    return this.http.put(`${BASE_URL}${config.uniqueMark.disable}${config.uniqueMark.individual}`, data);
  }
  disableRange(data: IProductLabel) {
    return this.http.put(`${BASE_URL}${config.uniqueMark.disable}${config.uniqueMark.range}`, data);
  }

  disableBatch(data: IProductLabel) {
    return this.http.put(`${BASE_URL}${config.uniqueMark.disable}${config.uniqueMark.batch}`, data);
  }
  public getAllLots(optionQuery: OptionQuery) {
    let query = `${BASE_URL_LOTS}?`;
    if (optionQuery.offset && optionQuery.limit) {
      query += `offset=${optionQuery.offset}&limit=${optionQuery.limit}&`;
    }
    if (optionQuery.sortDirection && optionQuery.sortField)
      query += `sortField=${optionQuery.sortField}&sortDirection=${optionQuery.sortDirection}&`;
    if (optionQuery.filterField && optionQuery.filterValue)
      query += `filterField=${optionQuery.filterField}&filterValue=${optionQuery.filterValue}&`;
    if (optionQuery.boolField && optionQuery.boolValue !== undefined)
      query += `boolField=${optionQuery.boolField}&boolValue=${optionQuery.boolValue}`;
    return this.http.get(query);
  }

  public getAll(optionQuery: OptionQuery) {
    let query = `${config.api.url}${config.identity.identityAll}?offset=${optionQuery.offset}&limit=${optionQuery.limit}`;
    if (optionQuery.sortDirection && optionQuery.sortField)
      query += `&sortField=${optionQuery.sortField}&sortDirection=${optionQuery.sortDirection}`;
    if (optionQuery.filterField && optionQuery.filterValue)
      query += `&filterField=${optionQuery.filterField}&filterValue=${optionQuery.filterValue}`;
    if (optionQuery.boolField && optionQuery.boolValue !== undefined)
      query += `&boolField=${optionQuery.boolField}&boolValue=${optionQuery.boolValue}`;
    return this.http.get(query);
  }

  public getByUserId(idUser: string, network: string) {
    return this.http.get(`${BASE_URL}/${idUser}/${network}`);
  }
  validateActive(uniqueMark: string, businessId: string, iceSku: string) {
    return this.http.get(`${BASE_URL}${config.uniqueMark.validateActive}${uniqueMark}/${businessId}/${iceSku}`);
  }

  validate(control: AbstractControl): Observable<ValidationErrors> | null {
    const validationString = control.value;
    return this.http.get(`${BASE_URL}${config.uniqueMark.validateActive}${validationString}`);
  }
  validateRange(data: IValidationRange) {
    return this.http.post(`${BASE_URL}${config.uniqueMark.filter}`, data);
  }
  validateConsolidate(data: IValidationRange) {
    return this.http.post(`${BASE_URL}${config.uniqueMark.consolidate}`, data);
  }
  validateRangePublic(data: IValidationRange) {
    return this.http.post(`${BASE_URL_PUBLIC}${config.uniqueMarkPublic.count}`, data);
  }

  validateDisable(uniqueMark: string, businessId: string) {
    return this.http.get(`${BASE_URL}${config.uniqueMark.validateDisable}${uniqueMark}/${businessId}`);
  }
  calculatorUniqueMarkEnd(uniqueMarkStart: string, amount: string, businessId: string) {
    return this.http.get(`${BASE_URL}${uniqueMarkStart}/${amount}/${businessId}`);
  }

  validateLoteUniqueMark(uniqueMark: string, businessId: string) {
    return this.http.get(`${BASE_URL}${uniqueMark}/${businessId}`);
  }
}

