import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'app/@core/services/toast.service';
import { IResponeRangePublic, IResponseValidationRange } from 'app/shared/models/unique.marks.mode';
import { UniqueMarkService } from 'app/shared/services/unique-mark.service';

const UNIQUE_MARKS = 'um';
@Component({
  selector: 'ngx-label-count',
  templateUrl: './label-count.component.html',
  styleUrls: ['./label-count.component.scss'],
})
export class LabelCountComponent implements OnInit {

  form: FormGroup;

  listUniqueMark: {
    uniqueMarkStar: string,
    uniqueMarkEnd: string,
    total: string,
  }[];
  response: IResponeRangePublic;
  total: string;
  loading = false;
  constructor(
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private uniqueMarkService: UniqueMarkService,
  ) { }

  ngOnInit(): void {
    this.total = '';
    this.form = this.formBuilder.group({
      uniqueMark: [null, Validators.required],
      uniqueMarkEnd: [null, Validators.required],
      status: [-1],
    });
    localStorage.setItem(UNIQUE_MARKS, JSON.stringify([]));
  }

  onValidateRange() {
    this.form.get('status').setValue(-1);
    const data = this.form.value;
    this.loading = true;
    this.total = '';
    this.uniqueMarkService.validateRangePublic(data).subscribe(res => {
      this.response = res as IResponeRangePublic;
      this.total = this.response[0].count;
      this.loading = false;
      this.listUniqueMark = JSON.parse(localStorage.getItem(UNIQUE_MARKS));
      if (!this.listUniqueMark) {
        this.listUniqueMark = [];
      }
      const item = { uniqueMarkStar: data.uniqueMark, uniqueMarkEnd: data.uniqueMarkEnd, total: this.total };
      this.listUniqueMark.push(item);
      localStorage.setItem(UNIQUE_MARKS, JSON.stringify(this.listUniqueMark));
      this.listUniqueMark = this.listUniqueMark.reverse();
      // this.clear();
    }, err => {
      this.loading = false;
      if (err.error) {
        const description: string = err.error.description;
        if (description) {
          if (description.includes('Unique mark end does not exist')) {
            this.toastService.showWarning('Atención', 'La etiqueta final no existe');
            this.form.controls.uniqueMarkEnd.setErrors({ description: true });
          } else if (description.includes('Unique mark initial does not exist')) {
            this.toastService.showWarning('Atención', 'La etiqueta inicial no existe');
            this.form.controls.uniqueMark.setErrors({ description: true });
          } else if (description.includes('Unique marks do not exist')) {
            this.toastService.showWarning('Atención', 'Las etiquetas no existen');
            this.form.controls.uniqueMark.setErrors({ description: true });
            this.form.controls.uniqueMarkEnd.setErrors({ description: true });
          } else if (description.includes('Wrong start and end range')) {
            this.toastService.showWarning('Atención', 'Rangos incorrectos');
            this.form.controls.uniqueMark.setErrors({ description: true });
            this.form.controls.uniqueMarkEnd.setErrors({ description: true });
          } else {
            this.toastService.showWarning('Atención', 'Error, comuníquese con el administrador');
          }
        } else {
          this.toastService.showWarning('Atención', 'Error, comuníquese con el administrador');
        }
      } else {
        this.toastService.showWarning('Atención', 'Error, comuníquese con el administrador');
      }
    });
  }
  clear() {
    localStorage.setItem(UNIQUE_MARKS, JSON.stringify([]));
    this.listUniqueMark = null;
    this.form.reset();
  }

}
