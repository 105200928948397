<div class="toast_wrapper" (mouseenter)="stopCountDown()" (mouseleave)="countDown()"
    [ngClass]="{'success': toastService.data && toastService.data.type === 0, 'warning': toastService.data && toastService.data.type === 1 }"
    [@openClose]="toastService.data && toastService.data.show ? 'open' : 'closed'">
    <div class="inner_wrapper">
        <div class="row m-0" *ngIf="toastService.data">
            <div class="col-auto vertical-align">
                <em *ngIf="toastService.data && toastService.data.type === 0"
                    class="bi bi-check-circle text-success"></em>
                <em *ngIf="toastService.data && toastService.data.type === 1"
                    class="bi bi-exclamation-triangle-fill text-warning"></em>
                <em *ngIf="toastService.data && toastService.data.type === 2"
                    class="bi bi-x-circle text-danger"></em>
            </div>
            <div class="col">
                <h5 class="toast_title dark-text">
                    {{ toastService.data.title }}
                </h5>
                <div class="toast_text dark-text">
                    {{ toastService.data.message }}
                </div>
            </div>
            <button type="button" class="btn btn-light px-2 py-0.5 mx-1 border width-botton botton-color mb-1" (click)="close()" style="font-size: 12px;">
                <em class="pi pi-times mr-1 font-weight-bold" style="font-size: 10px;"></em>&nbsp;Cerrar
            </button>
        </div>
    </div>
</div>