import { Component } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { IProfile } from 'app/shared/models/profile.model';
import { ProfileService } from 'app/shared/services/profile.service';
import { AuthService } from './auth/services';


@Component({
  selector: 'ngx-pages',
  styleUrls: ['pages.component.scss'],
  template: `
    <ngx-one-column-layout>
      <nb-menu [items]="menu"></nb-menu>
      <router-outlet></router-outlet>
    </ngx-one-column-layout>
  `,
})
export class PagesComponent {

  menu: NbMenuItem[] = [];
  constructor(protected profileService: ProfileService, private authService: AuthService) {
    const userId = this.authService.getUserIdLogin();
    this.profileService.get(userId).subscribe(res => {
      const profile = res as IProfile;
      for (let i = 0; i < profile.menu.length; i++) {
        const element = profile.menu[i];
        const children: NbMenuItem[] = [];
        element.children.forEach(child => {
          children.push({ title: child.name, link: child.path, icon: child.icon });
        });
        this.menu.push({
          title: element.name, link: element.path, icon: element.icon,
          children: children.length > 0 ? children : null,
        });
      }
    });
  }

}
