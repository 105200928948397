import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { PagesComponent } from './pages.component';
import { GuardService } from './auth/services';

const routes: Routes = [{
  path: '',
  component: PagesComponent,
  children: [
    {
      path: 'dashboard',
      loadChildren: () =>
        import('../modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    },
    {
      path: 'users',
      loadChildren: () =>
        import('../modules/main-admin/user/user.module').then(m => m.UserModule),
      canActivate: [GuardService],
      data: { path: '/app/users' },
    },
    {
      path: 'instance',
      loadChildren: () =>
        import('./main-admin/instance/instance.module').then(m => m.InstanceModule),
      canActivate: [GuardService],
      data: { path: '/app/instance' },
    },
    {
      path: 'business',
      loadChildren: () =>
        import('../modules/main-admin/business/business.module').then(m => m.BusinessModule),
      canActivate: [GuardService],
      data: { path: '/app/business' },
    },
    {
      path: 'account',
      loadChildren: () =>
        import('../modules/auth/account/account.module').then(m => m.AccountModule),
    },
    {
      path: 'product',
      loadChildren: () =>
        import('./products/product.module').then(m => m.ProductModule),
      canActivate: [GuardService],
      data: { path: '/app/product' },
    },
    {
      path: 'product-label',
      loadChildren: () =>
        import('./product-labels/label-individual/product-label.module').then(m => m.ProductLabelModule),
      canActivate: [GuardService],
      data: { path: '/app/product-label' },
    },
    {
      path: 'validate-unique-mark',
      loadChildren: () =>
        import('./validate-unique-mark/validate-unique-mark.module').then(m => m.ValidateUniqueMarkModule),
      canActivate: [GuardService],
      data: { path: '/app/validate-unique-mark' },
    },
    {
      path: 'business-intelligence',
      loadChildren: () =>
        import('./business-intelligence/business-intelligence.module').then(m => m.BusinessIntelligenceModule),
      canActivate: [GuardService],
      data: { path: '/app/business-intelligence' },
    },
    {
      path: 'shipment-ftp',
      loadChildren: () =>
        import('./shipment-ftp/shipment-ftp.module').then(m => m.ShipmentFtpModule),
      canActivate: [GuardService],
      data: { path: '/app/shipment-ftp' },
    },
    {
      path: 'product-report',
      loadChildren: () =>
        import('./product-report/product-report.module').then(m => m.ProductReportModule),
      canActivate: [GuardService],
      data: { path: '/app/product-report' },
    },
    {
      path: 'purchase-orders',
      loadChildren: () =>
        import('./purchase-orders/purchase-orders.module').then(m => m.PurchaseOrdersModule),
      canActivate: [GuardService],
      data: { path: '/app/purchase-orders' },
    },
    {
      path: 'label-range',
      loadChildren: () =>
        import('./product-labels/label-range/label-range.module').then(m => m.LabelRangeModule),
      canActivate: [GuardService],
      data: { path: '/app/label-range' },
    },
    {
      path: 'label-batch',
      loadChildren: () =>
        import('./product-labels/label-batch/label-batch.module').then(m => m.LabelBatchModule),
      canActivate: [GuardService],
      data: { path: '/app/label-batch' },
    },
    {
      path: 'disable-individual',
      loadChildren: () =>
        import('./disable-label/disable-individual/disable-label.module').then(m => m.DisableLabelModule),
      canActivate: [GuardService],
      data: { path: '/app/disable-individual' },
    },
    {
      path: 'disable-range',
      loadChildren: () =>
        import('./disable-label/disable-range/disable-range.module').then(m => m.DisableRangeModule),
      canActivate: [GuardService],
      data: { path: '/app/disable-range' },
    },
    {
      path: 'disable-batch',
      loadChildren: () =>
        import('./disable-label/disable-batch/disable-batch.module').then(m => m.DisableBatchModule),
      canActivate: [GuardService],
      data: { path: '/app/disable-batch' },
    },
    {
      path: 'roles',
      loadChildren: () =>
        import('../modules/main-admin/role/role.module').then(m => m.RoleModule),
      canActivate: [GuardService],
      data: { path: '/app/roles' },
    },
    {
      path: 'catalog',
      loadChildren: () =>
        import('../modules/main-admin/catalog/catalog.module').then(m => m.CatalogModule),
      canActivate: [GuardService],
      data: { path: '/app/catalog' },
    },
    {
      path: 'report',
      loadChildren: () =>
        import('../modules/report/report.module').then(m => m.ReportModule),
      canActivate: [GuardService],
      data: { path: '/app/report' },
    },
    {
      path: 'batch',
      loadChildren: () =>
        import('../modules/batch/batch.module').then(m => m.BatchModule),
      canActivate: [GuardService],
      data: { path: '/app/batch' },
    },
    {
      path: 'range-amount',
      loadChildren: () =>
        import('../modules/product-labels/label-range-amount/label-range-amount.module')
          .then(m => m.LabelRangeAmountModule),
      canActivate: [GuardService],
      data: { path: '/app/range-amount' },
    },
    {
      path: 'report-range',
      loadChildren: () =>
        import('../modules/report-range/report-range.module').then(m => m.ReportRangeModule),
      canActivate: [GuardService],
      data: { path: '/app/report-range' },
    },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {
}
