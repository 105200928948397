import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from 'app/config';
import { tap } from 'rxjs/operators';

const BASE_URL = `${config.api.url}${config.user.mainEndpoint}`;

@Injectable({
  providedIn: 'root',
})
export class ProfileService {

  constructor(private http: HttpClient) { }

  private _business: any;

  get business() {
    return { ...this._business };
  }

  public get(userId: string) {
    return this.http.get(`${BASE_URL}${config.user.profile}${userId}`).pipe(tap(res => {
      const response: any = res;
      this._business = response.business;
    }));
  }
}
