
import { SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { OptionQuery } from '../models/option-query.model';

export abstract class LoadDataComponent {

    sortOptions: SelectItem[];
    sortOrder: number;
    limit: number = 10;
    limitData: number = 9; // Cuando  es dataview
    limitTable: number = 9; // Cuando solo es tabla
    range: string;
    rangeDates: Date[];
    allDebounceTime: number = 800;
    sortField: string;
    totalRecords: any = 0;
    currentPage: number;
    isLoaded: boolean;
    detailLoading: boolean;
    searchUpdate = new Subject<string>();
    optionQuery: OptionQuery;
    boolField: string;
    boolValue: boolean;

    search(value) {
        this.optionQuery.filterValue = value;
    }

    searchStatus(value) {
        this.optionQuery.offset = 1;
        this.optionQuery.boolField = 'status';
        this.optionQuery.boolValue = value.value;
    }
    paginate(event) {
        this.isLoaded = false;
        const offset = event.page + 1;
        this.optionQuery.offset = offset;
    }

    sortChange(event) {
        this.optionQuery.offset = 1;
        const value = event.value;
        this.sortField = value.replace('!', '');
        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.optionQuery.sortField = value.substring(1, value.length);
            this.optionQuery.sortDirection = 'DESC';
        } else {
            this.sortOrder = 1;
            this.optionQuery.sortField = value;
            this.optionQuery.sortDirection = 'ASC';
        }
    }

    searchRange(value) {
        this.range = value;
        this.optionQuery.offset = 1;
    }
}
