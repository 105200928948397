import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/modules/auth/services';
import { LoadDataComponent } from 'app/shared/classes/load-data.component';
import { IBusiness } from 'app/shared/models/business.model';
import { IProductView } from 'app/shared/models/product.model';
import { ProductService } from 'app/shared/services/product.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'ngx-modal-product',
  templateUrl: './modal-product.component.html',
  styleUrls: ['./modal-product.component.scss'],
})
export class ModalProductComponent extends LoadDataComponent implements OnInit {
  listProduct: IProductView[];
  listProductResume: any[];
  business: IBusiness;
  productSearchUpdate = new Subject<string>();
  FIELD_SEARCH = 'name';

  @Input() visibleProduct: boolean;
  @Output() iProductView = new EventEmitter();
  @Output() cancel = new EventEmitter();
  constructor(
    private productService: ProductService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
  ) {
    super();
    this.productSearchUpdate
      .pipe(
        debounceTime(this.allDebounceTime),
        distinctUntilChanged(),
      )
      .subscribe(value => {
        this.onSearch(value);
      });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((optionQuery) => {
      this.optionQuery = {
        limit: optionQuery.limit ? optionQuery.limit : this.limitTable,
        offset: optionQuery.offset ? optionQuery.offset : 1,
        filterField: this.FIELD_SEARCH, filterValue: optionQuery.filterValue,
        sortField: optionQuery.sortField,
        sortDirection: optionQuery.sortDirection,
        boolField: optionQuery.boolField, boolValue: optionQuery.boolValue,
      };
    });
    this.listProduct = [];
    this.listProductResume = [];
    this.getBusinessForUser();
  }
  getBusinessForUser() {
    const optionQuery = {
      boolField: 'status',
      boolValue: true,
    };
    const userId = this.authService.getUserIdLogin();
    this.productService.getBusinessForUser(userId).subscribe(res => {
      this.business = res['business'];
      this.productService.getBusinessOfProductById(optionQuery, this.business.id).
        subscribe((product) => {
          this.listProduct = product['data'];
          this.listProductResume = this.listProduct.map(item => ({
            description: item.description,
            iceSku: item.iceSku,
            id: item.id,
            status: item.status,
          }));
        });
    });
  }
  loadData(businessId) {
    this.productService.getByBusinessIdIcesku(businessId, this.optionQuery.filterValue).subscribe(res => {
      // this.listProduct = res as IProductView[];
      this.listProductResume = res as IProductView[];
    });
  }
  onSearch(value) {
    this.search(value.toUpperCase());
    if (this.optionQuery.filterValue) {
      this.loadData(this.business.id);
    } else {
      this.getBusinessForUser();
    }
  }
  productEmit(event: IProductView) {
    this.visibleProduct = false;
    this.iProductView.emit(event);
  }
  close() {
    this.visibleProduct = false;
    this.cancel.emit(false);
  }
}
