import { Injectable } from '@angular/core';
import * as jose from 'node-jose';

@Injectable({
  providedIn: 'root',
})
export class SignerService {

  constructor() { }

  /**
   * Firma data
   * @param data json de data
   * @param pk llave primada, PEM
   * @returns JWT o null si hubo un error
   */
  async signData(data, pk) {
    try {
      let alg;
      const key = await jose.JWK.asKey(pk, 'pem');
      if (key.kty === 'EC') {
        alg = 'ES256';
      } else {
        alg = 'RS256';
      }
      const signature =
        jose.JWS.createSign({
          alg, format: 'compact',
        }, key).
          update(JSON.stringify(data), 'utf8').
          final();
      // signing
      return await signature;
    } catch (error) {
      return null;
    }
  }
}

