<p-dialog *ngIf="cancellable==null" header="" [(visible)]="visible" [style]="{width: '15rem'}" [modal]="true"
    [closable]="false">
    <div class="d-flex justify-content-around">
        <p class="p-mt-1" style="font-size: 1.4rem;">Cargando...</p>
        <div class="spinner-border" style="width: 1.9rem; height: 1.9rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</p-dialog>
<p-dialog *ngIf="cancellable!=null" header="" [(visible)]="visible" [style]="{width: '23rem'}" [modal]="true"
    [closable]="false">
    <!-- <div class="d-flex justify-content-between"> -->
    <div class="container ">
        <!-- <p class="p-mt-1 p-mr-1" style="font-size: 1.1rem;">ESPERANDO FIRMA</p> -->
        <p class="p-mt-1 p-mr-1" style="font-size: 1.1rem;"> <b>ESPERANDO FIRMA</b></p>
        <div class="d-flex justify-content-center">
            <p style="font-size: 1.1rem;">Por favor abra la
                aplicación
                móvil de
                Safetrack en su celular y firme la transacción...</p>
            <div class="spinner-border" style="width: 1.4rem; height: 1.4rem; margin-top: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button nbButton status="danger" size="small" shape="round" (click)="onCancel()"
            class="p-d-flex p-mt-2 p-ml-auto pd-as-end" [disabled]="!cancellable">
            <nb-icon icon="close-outline" class="icon"></nb-icon>Cancelar
        </button>
    </ng-template>
</p-dialog>