<div class="col-12">
    <div style="font-weight: bolder;">Detalles de blockchain</div>
    <div class="row">
        <div class="col-12 col-md-6" style="margin-top: 1rem;">
            <p class="detail-text">Transacción</p>
            <a href="{{ networkExplorer }}tx/{{ txnNetwork }}" target="_blank" rel="noreferrer">
                {{ txSubstrStart }}....{{ txSubstrEnd }}
                <nb-icon icon="link-2-outline" class="icon"></nb-icon>
            </a>
        </div>
        <div *ngIf="!isOut" class="col-12 col-md-6" style="margin-top: 1rem;">
            <p class="detail-text">Trazabilidad</p>
            <a [routerLink]="['../../', did ,'traceability']" [queryParams]="{info: batch}">
              {{ didStart }}....{{ didEnd }}
                <nb-icon icon="link-2-outline" class="icon"></nb-icon>
            </a>
        </div>
        <div *ngIf="isOut"class="col-12 col-md-6" style="margin-top: 1rem;">
            <p class="detail-text">Trazabilidad</p>
            <a [routerLink]="['../../', did ,'traceability-out']" [queryParams]="{info: batch}">
              {{ didStart }}....{{ didEnd }}
                <nb-icon icon="link-2-outline" class="icon"></nb-icon>
            </a>
        </div>
    </div>
