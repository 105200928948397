import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

@Injectable()
export class SocketService {
    constructor(private socket: Socket) { }

    private async saveSocket() {
        const id = this.socket.ioSocket.id;
        const idLocal = localStorage.getItem('socket');
        if (id !== idLocal) {
            // await this.communicationService.createOrUpdate(id).toPromise();
            localStorage.setItem('socket', id);
        }
    }

    async connect() {
        await this.socket.connect();
    }

    connected() {
        return this.socket.ioSocket.connected;
    }

    getSigned() {
        return this.socket.fromEvent('sendSigned').pipe(map((data) => data));
    }

    async sendSign(data: any) {
        this.saveSocket();
        await this.socket.emit('sendSign', data);
    }

    async disconnect() {
        await this.socket.disconnect();
        await this.connect();
    }
}
