<input [disabled]="disabled" placeholder="Buscar...2" [(ngModel)]="filters.search" id="selected" type="text"
    class="form-control" (keyup)="toogle(true)" (click)="toogle()" autocomplete="off" [ngClass]="{ 'is-invalid': error }"/>
<div class="invalid-feedback">Campo requerido</div>
<div *ngIf="settings.options && values" class="data" id="lista">
    <div class="row option" *ngFor="let value of values | input: this.filters.search" (click)="choose(value)">
        <div class="col">
            {{ value.name }}
        </div>
        <div class="col-auto" *ngIf="value.code">
            {{ value.code }}
        </div>
    </div>
    <div class="empty text-muted" *ngIf="(values | input: this.filters.search).length === 0">
        No existen coincidencias
    </div>
    <!-- <div class="option" *ngFor="let value of values">{{value.label}}</div> -->
</div>