import { ExtraOptions, Resolve, Router, RouterModule, Routes } from '@angular/router';
import { Injectable, NgModule } from '@angular/core';
import { NbAuthComponent } from '@nebular/auth';
import { AuthService, GuardService } from './modules/auth/services';
import { EMPTY } from 'rxjs';
import { MENU_ITEMS_ADMIN} from './modules/pages-menu';


@Injectable({ providedIn: 'root' })
export class MenuResolve implements Resolve<any> {
  constructor(private authService: AuthService, private router: Router) { }

  async resolve() {
    if (this.authService.isAuthenticated()) {
      const decodeToken = this.authService.decodeToken();
      const roles: [] = decodeToken['realm_access']['roles'];

      for (let i = 0; i < roles.length; i++) {
        const role = roles[i];
        switch (role) {
          case 'offline_access':
            return MENU_ITEMS_ADMIN;
        }
      }
      this.router.navigate(['404']);
      return EMPTY;
    } else {
      this.router.navigate(['404']);
      return EMPTY;
    }
  }
}


export const routes: Routes = [
  {
    path: 'app',
    loadChildren: () =>
      import('./modules/pages.module').then(m => m.PagesModule),
    canActivate: [GuardService],
    resolve: {
      menu: MenuResolve,
    },
  },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/auth/auth.module').then(m => m.AuthModule),
      },
    ],
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'auth' },
  // { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
