import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'app/config';
import { OptionQuery } from '../models/option-query.model';
import { INewPurchaseOrder, IPurchaseOrder, IPurchaseOrderUpdateDTO, IUpdateDocumentPurchaseOrder, PurchaseOrder } from '../models/purchase-order.model';
import { IResponseStock } from '../models/sismode-stock.model';
const BASE_URL = `${config.api.url}${config.purchaseOrder.mainEndpoint}`;

@Injectable({
  providedIn: 'root',
})
export class PurchaseOrderService {

  constructor(protected http: HttpClient) { }

  private _state = {
      pageNumber: 1,
      itemsPerPaginate: 9,
      sortDirection: 'DESC',
      status: '',
      searchBusiness: '',
      viewType: 'listView',
  };

  get state() {
    return { ...this._state };
  }

  public setStatePurchaseOrderList(currentState: any) {
    this._state = currentState;
  }

  public getPurchaseOrders(optionQuery: OptionQuery, relations: string) {
    let query = `${BASE_URL}?`;
    if (optionQuery.offset && optionQuery.limit) {
      query += `offset=${optionQuery.offset}&limit=${optionQuery.limit}&`;
    }
    if (optionQuery.sortDirection && optionQuery.sortField)
      query += `sortField=${optionQuery.sortField}&sortDirection=${optionQuery.sortDirection}&`;
    if (optionQuery.filterField && optionQuery.filterValue)
      query += `filterField=${optionQuery.filterField}&filterValue=${optionQuery.filterValue}&`;
    if (optionQuery.boolField && optionQuery.boolValue !== undefined)
      query += `boolField=${optionQuery.boolField}&boolValue=${optionQuery.boolValue}`;
    query += `relations=${relations}`;
    return this.http.get<PurchaseOrder>(query);
  }

  public getPurchaseOrderByBusiness(businessId: string) {
    const query = `${BASE_URL}${config.purchaseOrder.business}${businessId}?relations=status`;
    return this.http.get(query);
  }

  public getPurchaseOrdersByBusinessId(optionQuery: OptionQuery, id: string, relations: string) {
    let query = `${BASE_URL}${config.purchaseOrder.business}${id}?`;
    if (optionQuery.offset && optionQuery.limit) {
      query += `offset=${optionQuery.offset}&limit=${optionQuery.limit}&`;
    }
    if (optionQuery.sortDirection && optionQuery.sortField)
      query += `sortField=${optionQuery.sortField}&sortDirection=${optionQuery.sortDirection}&`;
    if (optionQuery.filterField && optionQuery.filterValue)
      query += `filterField=${optionQuery.filterField}&filterValue=${optionQuery.filterValue}&`;
    if (optionQuery.boolField && optionQuery.boolValue !== undefined)
      query += `boolField=${optionQuery.boolField}&boolValue=${optionQuery.boolValue}`;
      query += `relations=${relations}`;
    return this.http.get<PurchaseOrder>(query);
  }

  public getPurchaseOrdersFilterByStatus(optionQuery: OptionQuery, relations: string, businessId: string,
    statusId: string) {
    let query = `${BASE_URL}${config.purchaseOrder.business}${businessId}/${config.purchaseOrder.status}${statusId}?`;
    if (optionQuery.offset && optionQuery.limit) {
      query += `offset=${optionQuery.offset}&limit=${optionQuery.limit}&`;
    }
    if (optionQuery.sortDirection && optionQuery.sortField)
      query += `sortField=${optionQuery.sortField}&sortDirection=${optionQuery.sortDirection}&`;
    if (optionQuery.filterField && optionQuery.filterValue)
      query += `filterField=${optionQuery.filterField}&filterValue=${optionQuery.filterValue}&`;
    if (optionQuery.boolField && optionQuery.boolValue !== undefined)
      query += `boolField=${optionQuery.boolField}&boolValue=${optionQuery.boolValue}`;
    query += `relations=${relations}`;
    return this.http.get<PurchaseOrder>(query);
  }

  public postPurchaseOrder(purchaseOrder: INewPurchaseOrder) {
    const query = `${config.api.url}${config.purchaseOrder.directEndpoint}`;
    return this.http.post<IPurchaseOrder>(query, purchaseOrder);
  }

  public update(idPurchaseOrder: string, purchaseOrder: IUpdateDocumentPurchaseOrder) {
    const query = `${config.api.url}${config.purchaseOrder.mainEndpoint}${config.purchaseOrder.uploadDocument}${idPurchaseOrder}`;
    return this.http.put(query, purchaseOrder);
  }

  public cancelOrder(idPurchaseOrder: string, PurcharseOrderUpdateDTO: IPurchaseOrderUpdateDTO) {
    const query = `${config.api.url}${config.purchaseOrder.mainEndpoint}${config.purchaseOrder.cancelOrder}${idPurchaseOrder}`;
    return this.http.put(query, PurcharseOrderUpdateDTO);
  }

  public getById(optionQuery: OptionQuery, relations: string, idPurchaseOrder: string) {
    let query = `${BASE_URL}${idPurchaseOrder}?`;
    if (optionQuery.offset && optionQuery.limit) {
      query += `offset=${optionQuery.offset}&limit=${optionQuery.limit}&`;
    }
    if (optionQuery.sortDirection && optionQuery.sortField)
      query += `sortField=${optionQuery.sortField}&sortDirection=${optionQuery.sortDirection}&`;
    if (optionQuery.filterField && optionQuery.filterValue)
      query += `filterField=${optionQuery.filterField}&filterValue=${optionQuery.filterValue}&`;
    if (optionQuery.boolField && optionQuery.boolValue !== undefined)
      query += `boolField=${optionQuery.boolField}&boolValue=${optionQuery.boolValue}`;
    query += `relations=${relations}`;
    return this.http.get<PurchaseOrder>(query);
  }

  public validationPay(idPurchaseOrder: string) {
    const URL = `${config.api.url}${config.purchaseOrder.validationEndpoint}`;
    const query = `${URL}${config.purchaseOrder.validation}${config.purchaseOrder.validationPay}${idPurchaseOrder}`;
    return this.http.put(query, '');
  }

  public invalidateDocument(idPurchaseOrder: string) {
    const URL = `${config.api.url}${config.purchaseOrder.mainEndpoint}`;
    const query = `${URL}${config.purchaseOrder.invalidateDocument}${idPurchaseOrder}`;
    return this.http.put(query, '');
  }

  public validationDai(idPurchaseOrder: string) {
    const URL = `${config.api.url}${config.purchaseOrder.validationEndpoint}`;
    const query = `${URL}${config.purchaseOrder.validation}${config.purchaseOrder.validationDai}${idPurchaseOrder}`;
    return this.http.put(query, '');
  }

  public sentToSismode(idPurchaseOrder: string) {
    const URL = `${config.api.url}${config.purchaseOrder.salesOrder}${idPurchaseOrder}`;
    return this.http.post(URL, '');
  }

  public getStatus() {
    const URL = `${config.api.url}${config.purchaseOrder.stock}status`;
    return this.http.get(URL);
  }

  public getStocks() {
    const query = `${config.api.url}${config.purchaseOrder.stock}`;
    return this.http.get<IResponseStock>(query);
  }
}
