<p-dialog [(visible)]="visible" [modal]="true" [closable]="false">
    <div>
        <h2>
            <p class="p-mt-1"><b>Resumen
                </b></p>
        </h2>
        <br>
        <div role="status">
            <p class="p-mt-1"><b>Etiquetas: </b>{{activationResponse.affected}} </p>
        </div>
        <br>
        <div role="status">
            <p class="p-mt-1"><b>Total etiquetas afectadas: </b>{{activationResponse.totalUpdate}}</p>
        </div>
        <br>
        <div role="status">
            <p class="p-mt-1"><b>Total etiquetas no afectadas: </b>{{activationResponse.totalNotUpdate}}</p>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <p-footer>
            <button nbButton status="info" size="small" shape="round" (click)="onAccept()"
                class="p-d-flex p-mt-2 p-ml-auto pd-as-end">
                Aceptar
            </button>
        </p-footer>
    </ng-template>
</p-dialog>
