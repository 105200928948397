import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Output } from '@angular/core';
import { AlertsService } from './alerts.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'closed',
        style({
          visibility: 'hidden',
          right: '-400px',
        }),
      ),
      state(
        'open',
        style({
          right: '40px',
        }),
      ),
      transition('open <=> closed', [animate('0.01s ease-in-out')]),
    ]),
  ],
})
export class AlertComponent {
  @Output() response = new EventEmitter();

  // Alert subscription
  // @autor: Carlos Sánchez Mora
  // @date: 02-08-2022
  constructor(public alertService: AlertsService) {this.alertService.open.subscribe(() => {}); }
  // Alert subscription closeº
  // @autor: Carlos Sánchez Mora
  // @date: 02-08-2022
  close(option: boolean): any {
    this.alertService.hide(option);
    return;
  }
}
