<div class="container">
    <form [formGroup]="form" (ngSubmit)="onValidateTicket()" aria-labelledby="title">
        <div class="d-flex justify-content-center px-5 mb-3">
            <h2>Valida tu etiqueta</h2>
        </div>
        <div class="d-flex justify-content-center px-5 mb-3">
            <div>
                <input id="uniqueMark" autocomplete="off" type="text" class="form-control text-center" formControlName="uniqueMark"
                    style="font-size: 200%; text-transform:uppercase;" maxlength="16" placeholder="código">
                <div *ngIf="form.controls['uniqueMark']?.invalid && (form.controls['uniqueMark'].dirty ||
                                    form.controls['uniqueMark'].touched)"
                    class="text-danger left-margin-message-content">
                    <div *ngIf="form.controls['uniqueMark'].errors">
                        Solo se permiten números y letras.
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center px-5">
            <re-captcha (resolved)="resolved($event)" [siteKey]="this.siteKey"
                formControlName="recaptchaReactive"></re-captcha>
        </div>
    </form>
    <div class="row" style="margin-top: 1rem;">
        <div class="col-12 col-md-6" style="margin-top: 1rem;">
            <button nbButton status="info" outline type="button" size="small" shape="round"
                class="btn-primary btn btn-block" [routerLink]="['../']">
                <nb-icon icon="arrow-back-outline"></nb-icon>
                Regresar
            </button>
        </div>
        <div class="col-12 col-md-6" style="margin-top: 1rem;">
            <button nbButton class="btn-success btn btn-block" size="small" status="success" [disabled]="form.invalid" shape="round"
                (click)="onValidateTicket()" style="width: 100%;">
                <nb-icon icon="checkmark-circle-outline"></nb-icon>
                Validar
            </button>
        </div>
    </div>
</div>