import { Validators, FormControl, FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class LoginForm {

  public getForm() {
    return new FormGroup({
      username: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      password: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      clientId: new FormControl('safetrack-cli'),
      grantType: new FormControl('password'),
    });
  }

}
