import { NgModule } from '@angular/core';
import {
  NbAccordionModule,
  NbButtonModule, NbCalendarModule, NbCardModule, NbDatepickerModule, NbIconModule, NbLayoutModule, NbMenuModule,
  NbNativeDateService,
  NbRadioModule,
  NbTabsetModule,
  NbTooltipModule,
} from '@nebular/theme';
import { ThemeModule } from '../@theme/theme.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PagesComponent } from './pages.component';
import { PagesRoutingModule } from './pages-routing.module';
import { ConfirmationService } from 'primeng/api';
import { SidebarModule } from 'primeng/sidebar';
import { DataViewModule } from 'primeng/dataview';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { TimelineModule } from 'primeng/timeline';
import { PaginatorModule } from 'primeng/paginator';
import { MatStepperModule } from '@angular/material/stepper';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SignerService } from 'app/shared/services/signer.service';
import { PrivateKeyFormComponent } from 'app/shared/components/private-key-form/private-key-form.component';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { NetworkExplorerComponent } from 'app/shared/components/network-explorer/network-explorer.component';
import { TitleCasePipe, DatePipe } from '@angular/common';
import { CalendarService } from 'app/shared/services/calendar.service';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { TableModule } from 'primeng/table';
import { ModalLoadingComponent } from 'app/shared/components/modal-loading/modal-loading.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { UtilitiesService } from 'app/shared/utils/utilities';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ModalResponseComponent } from 'app/shared/components/modal-response-label/modal-response.component';
import { UserListComponent } from 'app/shared/components/user-list/user-list.component';
import { ModalProductComponent } from 'app/shared/components/modal-product/modal-product.component';
import { SimpleSelectComponent } from 'app/shared/components/simple-select/simple-select.component';
import { ListCardComponent } from 'app/shared/components/list-card/list-card.component';
import { StatusBarComponent } from 'app/shared/components/status-bar/status-bar.component';
import { PaginationComponent } from 'app/shared/components/pagination/pagination.component';
import { SearchSelectComponent } from 'app/shared/components/search-select/search-select.component';
import { GridCardComponent } from 'app/shared/components/grid-card/grid-card.component';
import { SearchComponent } from 'app/shared/components/search/search.component';
import { DirectionCardComponent } from 'app/shared/components/direction-card/direction-card.component';
import { UploadModalComponent } from 'app/shared/components/upload-modal/upload-modal.component';

const NB_MODULES = [
  NbCardModule,
  NbIconModule,
  NbButtonModule,
  NbTooltipModule,
  NbCalendarModule,
  NbAccordionModule,
  NbRadioModule,
  NbTabsetModule,
  NbDatepickerModule,
  NbLayoutModule,
];
const PN_MODULES = [
  SidebarModule,
  DataViewModule,
  ConfirmDialogModule,
  DropdownModule,
  InputTextareaModule,
  DialogModule,
  TimelineModule,
  PaginatorModule,
  MessagesModule,
  MessageModule,
  CalendarModule,
  ChartModule,
  TableModule,
  InputSwitchModule,
];

const MAT_MODULES = [
  MatStepperModule,
];

const SHARED_MODULES = [
  FormsModule,
  ReactiveFormsModule,
  DragDropModule,
];

const SHARED_COMPONENTS = [
  PrivateKeyFormComponent,
  NetworkExplorerComponent,
  ModalLoadingComponent,
  ModalResponseComponent,
  UserListComponent,
  ModalProductComponent,
  SimpleSelectComponent,
  ListCardComponent,
  StatusBarComponent,
  PaginationComponent,
  SearchSelectComponent,
  GridCardComponent,
  SearchComponent,
  DirectionCardComponent,
  UploadModalComponent,
];

@NgModule({
  imports: [
    PagesRoutingModule,
    ThemeModule,
    NbMenuModule,
    PaginatorModule,
    NgxQRCodeModule,
    ...NB_MODULES,
    ...PN_MODULES,
    ...MAT_MODULES,
    ...SHARED_MODULES,
  ],
  declarations: [
    PagesComponent,
    ...SHARED_COMPONENTS,
  ],
  exports: [
    ...NB_MODULES,
    ...PN_MODULES,
    ...MAT_MODULES,
    ...SHARED_MODULES,
    ...SHARED_COMPONENTS,
  ],
  providers: [
    ConfirmationService,
    SignerService,
    CalendarService,
    TitleCasePipe,
    DatePipe,
    NbNativeDateService,
    UtilitiesService,
  ],
})
export class PagesModule {
}
