<div class="simple-select">
    <div id="selected" class="form-group" (click)="toogle()">
        <div class="form-control-prepend pt-2">
            <em class="{{icon}}"></em>
        </div>
        <div class="form-control-append">
            <nb-icon icon="arrow-ios-downward-outline" class="icon"></nb-icon>
        </div>
        <input type="text" class="form-control color-icon" (blur)='exitSelect()' [value]="this.title" readonly>
    </div>
    <div *ngIf="this.settings.options" class="data" id="lista" [ngStyle]="settings">
        <div class="row option" *ngFor="let value of values" (click)="choose(value)">
            <div class="col">
                {{value.text}}
            </div>
        </div>
    </div>
</div>