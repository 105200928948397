import { Injectable } from '@angular/core';
import { config } from 'app/config';
import { HttpClient } from '@angular/common/http';
import { OptionQuery } from '../models/option-query.model';
import { ICatalogValidation, IProduct, IProductUpdate, IValidationResponse } from '../models/product.model';
import { IBusinessClass } from '../models/menu.model';
import { Observable } from 'rxjs';

const BASE_URL = `${config.api.url}${config.product.mainEndpoint}`;
const BASE_URL_USER = `${config.api.url}${config.user.mainEndpoint}`;
const BASE_URL_CATALOG = `${config.api.url}${config.catalogDetail.mainEndpoint}`;
const BASE_URL_ENDORSE = `${config.api.url}${config.endorse.mainEndpoint}`;
const BASE_URL_REPORT = `${config.api.url}${config.product.reportProducts}`;

@Injectable({
  providedIn: 'root',
})

export class ProductService {

  constructor(protected http: HttpClient) { }

  create(data: IProduct) {
    return this.http.post(`${BASE_URL}`, data);
  }
  validationMark(data: IProduct) {
    return this.http.post(`${BASE_URL}${config.product.validationMark}`, data);
  }

  async validation(catalogValidate: ICatalogValidation[], authorization: string): Promise<IValidationResponse[]> {
    const header = {
      'Authorization': authorization,
      'Content-Type': 'application/json',
    };
    const result = await fetch(`${BASE_URL_CATALOG}${config.catalogDetail.validation}`, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(catalogValidate),
    });
    if (result.status === 200) {
      return await result.json();
    } else {
      throw Error('Error to consume service ');
    }
  }

  public getAll(optionQuery: OptionQuery, relations?: string) {
    let query = `${BASE_URL}?`;
    if (optionQuery.offset && optionQuery.limit) {
      query += `offset=${optionQuery.offset}&limit=${optionQuery.limit}&`;
    }
    if (optionQuery.sortDirection && optionQuery.sortField)
      query += `sortField=${optionQuery.sortField}&sortDirection=${optionQuery.sortDirection}&`;
    if (optionQuery.filterField && optionQuery.filterValue)
      query += `filterField=${optionQuery.filterField}&filterValue=${optionQuery.filterValue}&`;
    if (optionQuery.boolField && optionQuery.boolValue !== undefined)
      query += `boolField=${optionQuery.boolField}&boolValue=${optionQuery.boolValue}`;
    if (relations)
      query += `&relations=${relations}`;
    return this.http.get(query);
  }

  public getByBusinessIdIcesku(businessId: string, name: string) {
    const query = `${BASE_URL}${businessId}/${name}`;
    return this.http.get(query);
  }

  public deleteEndorse(data: IProduct, id: string) {
    return this.http.put(`${BASE_URL_ENDORSE}${id}`, data);
  }

  getById(id: string) {
    return this.http.get(`${BASE_URL}${id}${config.product.relations}${config.product.relationsBusiness}`);
  }

  getBusinessOfProductById(optionQuery: OptionQuery, id: string) {
    let query = `${BASE_URL}${config.product.business}${id}?`;
    if (optionQuery.offset && optionQuery.limit) {
      query += `offset=${optionQuery.offset}&limit=${optionQuery.limit}&`;
    }
    if (optionQuery.sortDirection && optionQuery.sortField)
      query += `sortField=${optionQuery.sortField}&sortDirection=${optionQuery.sortDirection}&`;
    if (optionQuery.filterField && optionQuery.filterValue)
      query += `filterField=${optionQuery.filterField}&filterValue=${optionQuery.filterValue}&`;
    if (optionQuery.boolField && optionQuery.boolValue !== undefined)
      query += `boolField=${optionQuery.boolField}&boolValue=${optionQuery.boolValue}`;
    return this.http.get(query);
  }

  getBusinessAllProductById(optionQuery: OptionQuery, id: string) {
   let query = `${BASE_URL}${config.product.all}${config.product.business}${id}?`;
    if (optionQuery.offset && optionQuery.limit) {
      query += `offset=${optionQuery.offset}&limit=${optionQuery.limit}&`;
    }
    if (optionQuery.sortDirection && optionQuery.sortField)
      query += `sortField=${optionQuery.sortField}&sortDirection=${optionQuery.sortDirection}&`;
    if (optionQuery.filterField && optionQuery.filterValue)
      query += `filterField=${optionQuery.filterField}&filterValue=${optionQuery.filterValue}&`;
    if (optionQuery.boolField && optionQuery.boolValue !== undefined)
      query += `boolField=${optionQuery.boolField}&boolValue=${optionQuery.boolValue}`;
    return this.http.get(query);
  }

  update(data: IProductUpdate, id: string) {
    return this.http.put(`${BASE_URL}${id}`, data);
  }
  updateProduct(file: any, data: IProduct, id: string) {
    const formData: FormData = new FormData();
    if (data.sanitaryDateDoc) {
      formData.append('sanitaryDateDoc', String(data.sanitaryDateDoc));
    }
    if (data.expirationDate) {
      formData.append('expirationDate', String(data.expirationDate));
    }
    if (data.statusProduct) {
      formData.append('statusProduct', String(data.statusProduct));
    }
    if (data.statusSanitaryDoc) {
      formData.append('statusSanitaryDoc', String(data.statusSanitaryDoc));
    }
    if (data.codeEan13) {
      formData.append('codeEan13', String(data.codeEan13));
    }
    if (data.photoDocument) {
      formData.append('photoDocument', String(data.photoDocument));
    }
    if (data.sanitaryNumberDoc) {
      formData.append('sanitaryNumberDoc', String(data.sanitaryNumberDoc));
    }
    if (file) {
      formData.append('file', file);
    }
    formData.append('iceSku', data.iceSku);
    formData.append('productType', data.productType);
    formData.append('description', data.description);
    formData.append('businessId', data.businessId);
    formData.append('aggregationPolicieId', data.aggregationPolicieId);
    return this.http.put(`${BASE_URL}${id}`, formData);
  }
  updateProductWithoutValidations(data: any, id: string) {
    return this.http.put(`${BASE_URL}${id}`, data);
  }
  getBusinessForUser(id: string): Observable<IBusinessClass> {
    return this.http.get<IBusinessClass>(`${BASE_URL_USER}${config.user.profile}${id}`);
  }

  public downloadExcel(businessId: string) {
    return this.http.get(`${BASE_URL_REPORT}${businessId}`, { responseType: 'blob' });
  }

  downloadGeneralReportWithEndorsedExcel() {
    return this.http.get(`${BASE_URL_REPORT}${config.product.allEndorse}`, { responseType: 'blob' });
  }
}
