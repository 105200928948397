import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ngx-simple-select',
  templateUrl: './simple-select.component.html',
  styleUrls: ['./simple-select.component.scss'],
})
export class SimpleSelectComponent implements OnInit {

  @Input() title!: string;
  @Input() values!: string[];
  @Input() icon!: string[];
  @Output() selected: EventEmitter<any> = new EventEmitter();

  settings = {
    options: false,
    width: '',
  };

  constructor() { }

  ngOnInit(): void {
    const element: any = document.getElementById('selected');
    this.settings.width = `${element.offsetWidth}px`;
  }

  onCancel() { }

  // Exit element when deselect itself
  // @autor: Daniel Tapia López
  // @date: 27-07-2022
  exitSelect() {
    setTimeout(() => {
      this.settings.options = false;
    }, 120);
  }

  // Open the list
  // @autor: Daniel Tapia López
  // @date: 28-07-2022
  toogle() {
    this.settings.options = !this.settings.options;
  }

  // Select the option in the component title
  // @autor: Daniel Tapia López
  // @date: 27-07-2022
  choose(item: any) {
    this.settings.options = false;
    this.title = item.text;
    this.selected.emit(
      {
        id: item.id,
        text: item.text,
      });
  }

}
