import { Injectable } from '@angular/core';
import {
  CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, CanDeactivate,
} from '@angular/router';

import { JwtTokenService } from './jwt-token.service';
import { AuthService } from './auth.service';
import { ProfileService } from 'app/shared/services/profile.service';
import { IMenu } from 'app/shared/models/menu.model';
import { IProfile } from 'app/shared/models/profile.model';

@Injectable()
export class GuardService implements CanActivate, CanActivateChild, CanDeactivate<unknown> {

  public rolSuperAdmin: any;

  constructor(private router: Router, private jwtService: JwtTokenService,
    private authService: AuthService, private profileService: ProfileService) {

  }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    try {
      if (this.jwtService.isExpiredToken(localStorage.getItem('access_token'))) {
        if (localStorage.getItem('access_token')) {
          this.authService.refreshToken().subscribe(() => {
          }, () => {
            this.authService.logout();
            return false;
          });
        } else {
          this.router.navigate([`/auth`], { queryParams: { redirect: state.url } });
        }
      }
      if (route.data['path']) {
        const userId = this.authService.getUserIdLogin();
        const profile = await this.profileService.get(userId).toPromise() as IProfile; if (!profile.menu)
          this.router.navigate([`/auth`]);
        else
          if (this.checkAuthPaths(profile.menu, route.data['path']))
            return true;
          else
            this.router.navigate([`/auth`]);
      } else {
        return true;
      }
    } catch {
      this.router.navigate([`/auth`], { queryParams: { redirect: state.url } });
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot) {
    try {
      if (this.jwtService.isExpiredToken(localStorage.getItem('access_token'))) {
        if (localStorage.getItem('access_token')) {
          this.authService.refreshToken().subscribe(() => {
          }, () => {
            this.authService.logout();
            return false;
          });
        } else {
          window.sessionStorage.clear();
          this.router.navigate([`/auth`], { queryParams: { redirect: this.router.url } });
        }
      }
      let role;
      const rolAuth = this.authService.getUserRoleLogin();

      for (let i = 0; i < rolAuth.length; i++) {
        if (
          // rolAuth[i] !== 'offline_access' &&
          rolAuth[i] !== 'uma_authorization') {
          role = rolAuth[i];
        }
      }
      if (route.data.rol[0] === role) {
        return true;
      } else {
        window.alert('Usuario no autorizado');
        window.sessionStorage.clear();
        localStorage.clear();
        this.router.navigate([`/auth`]);
        return false;
      }
    } catch {
      window.sessionStorage.clear();
      this.router.navigate([`/auth`], { queryParams: { redirect: this.router.url } });
    }
  }

  canDeactivate() {
    return true;
  }

  checkAuthPaths(menu: IMenu[], path: string) {
    for (let i = 0; i < menu.length; i++) {
      const element = menu[i];
      if (element.path === path) {
        return true;
      }
      for (let j = 0; j < element.children.length; j++) {
        const child = element.children[j];
        if (child.path === path) {
          return true;
        }
      }
    }
    return false;
  }
}
