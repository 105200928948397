import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { AuthService } from './services/auth.service';
import { ThemeModule } from 'app/@theme/theme.module';
import { NbInputModule, NbButtonModule, NbIconModule, NbAccordionModule } from '@nebular/theme';
import { InputTextModule } from 'primeng/inputtext';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { authInterceptorProviders } from './services/auth-interceptor.service';
import { GuardService, JwtTokenService } from './services';
import { LoginForm } from './models/login.form';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { LabelCountComponent } from './login/label-count/label-count.component';
import { LabelValidatorComponent } from './login/label-validator/label-validator.component';

@NgModule({
  declarations: [
    LoginComponent,
    ResetPasswordComponent,
    LabelCountComponent,
    LabelValidatorComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    FormsModule,
    AuthRoutingModule,
    ThemeModule,
    NbInputModule,
    NbButtonModule,
    InputTextModule,
    NbIconModule,
    NbAccordionModule,
  ],
  providers: [
    AuthService,
    authInterceptorProviders,
    JwtTokenService,
    GuardService,
    LoginForm,
  ],
})
export class AuthModule { }
