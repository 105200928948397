import { HostBinding, Injectable } from '@angular/core';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbToastrConfig, NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  @HostBinding('class')
  className = 'style-toast';
  constructor(private toastrService: NbToastrService) {

  }

  public showError(title: string, body: string) {
    this.createToast('danger', title, body);
  }

  public showInfo(title: string, body: string) {
    this.createToast('info', title, body);
  }

  public showInfoDown(title: string, body: string) {
    this.createToastDown('info', title, body);
  }

  public showPrimary(title: string, body: string) {
    this.createToast('primary', title, body);
  }

  public showSuccess(title: string, body: string) {
    this.createToast('success', title, body);
  }

  public showWarning(title: string, body: string) {
    this.createToast('warning', title, body);
  }

  private createToast(type: NbComponentStatus, title: string, body: string) {
    const configToast: Partial<NbToastrConfig> = {
      status: type,
      destroyByClick: true,
      duration: 7000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: false,
      iconPack: 'eva',
      toastClass: 'style-toast',
    };

    const titleContent = title ? ` ${title} ` : '';
    this.toastrService.show(body, titleContent, configToast);
  }

  private createToastDown(type: NbComponentStatus, title: string, body: string) {
    const configToast: Partial<NbToastrConfig> = {
      status: type,
      destroyByClick: true,
      duration: 7000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
      preventDuplicates: true,
      iconPack: 'eva',
      toastClass: 'style-toast',
      limit: 1,
      icon: 'info-outline',
    };

    const titleContent = title ? ` ${title} ` : '';
    this.toastrService.show(body, titleContent, configToast);
  }
}
