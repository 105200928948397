<div *ngIf="this.order.code != '99'">
    <div class="d-flex justify-content-center">
        <div *ngIf="this.ordersTotal.length == 0" class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="component-settings px-2 pr-3">
        <div class="content-wrap px-0">
            <div *ngFor="let orderBDD of ordersTotal; let i = index">
                <i class="icon1"
                    [ngClass]="orderBDD.code == '1' ? 'pi pi-circle-off': orderBDD.code == this.order.code ? 'pi pi-check-circle': 'pi pi-circle-off'"
                    [ngStyle]="{'color': (this.order.code == ordersTotal.length && orderBDD.code == ordersTotal.length) ?
                    'green': (this.order.code == '1' && orderBDD.code == '1') ?
                    'orange': this.order.code >= orderBDD.code ? 'blue': ''}"></i>
            </div>
        </div>
    </div>
</div>
<div class="component-settings">
    <div class="content-wrap-text pr-1">
        <div *ngFor="let orderBDD of ordersTotal; let i = index">
            <!-- <p *ngIf="orderBDD.code == order.code" id="generatedState" class="icon2 hidden"
                [ngStyle]="{'padding-right': orderBDD.name.length >= 16 ? '75px' : '0px'}">{{orderBDD.name}}</p> -->
            <p *ngIf="orderBDD.code == order.code" id="generatedState" class="icon2 hidden"
                [ngStyle]="{'padding-right': orderBDD.name.length >= 16 ? '75px' : '0px'}">
                {{ this.purchaseOrder.status.code == '4' && this.purchaseOrder.business.businessCredit ? 'TIENE CRÉDITO' :
                orderBDD.name }}
            </p>
        </div>
    </div>
</div>
<div *ngIf="this.order.code == '99'">
    <h6>ORDEN ANULADA</h6>
</div>