import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { LabelCountComponent } from './login/label-count/label-count.component';
import { LabelValidatorComponent } from './login/label-validator/label-validator.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'label-count',
    component: LabelCountComponent,
  },
  {
    path: 'label-validator',
    component: LabelValidatorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
