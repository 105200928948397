import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService } from 'app/@core/services/toast.service';
import { superadmin } from 'app/shared/constants/roles.constants';
import { PIPE_DATE } from 'app/shared/constants/shared-contants.constants';

@Component({
  selector: 'ngx-grid-card',
  templateUrl: './grid-card.component.html',
  styleUrls: ['./grid-card.component.scss'],
})
export class GridCardComponent implements OnInit {
  PIPE_DATE = PIPE_DATE;
  @Input() value!: string;
  @Input() status!: string;
  @Input() creationDate!: string;
  @Input() costValue!: string;
  @Input() order!: any;
  @Input() business!: any;
  @Input() role!: string;
  @Input() ordersTotal!: string[];
  @Output() validationDocument: EventEmitter<string> = new EventEmitter();

  optionsAdmin: boolean = false;
  documentToValidate: string;
  orderId: string;

  constructor(
    private toastDownService: ToastService,
  ) { }

  ngOnInit(): void {
    if (this.role === superadmin) {
      this.optionsAdmin = true;
    }
    const index = this.ordersTotal.indexOf('ANULADO');
    if (index !== -1) {
      this.ordersTotal.splice(index, 1);
    }
    this.orderId = this.order.id;
  }
  openModalValidatePay() {
    this.validationDocument.emit('validatePay');
    this.toastDownService.showInfo('Espere', 'Descarga en proceso');
  }
  openModalValidatePayExternal() {
    this.validationDocument.emit('validatePayExternal');
  }
  openModalValidateDai() {
    this.validationDocument.emit('validateDai');
    this.toastDownService.showInfo('Espere', 'Descarga en proceso');
  }

  uploadSismode() {
    this.validationDocument.emit('uploadSismode');
  }

  uploadDocument() {
    this.validationDocument.emit('uploadDocument');
  }

  downloadDocumentCredit() {
    this.validationDocument.emit('downloadDocumentCredit');
  }

  uploadTax() {
    this.validationDocument.emit('uploadTax');
  }

  cancelOrder() {
    this.validationDocument.emit('cancelOrder');
  }

  uploadInvoice() {
    this.validationDocument.emit('uploadInvoice');
  }

  downloadValidPay() {
    this.validationDocument.emit('downloadValidPay');
    this.toastDownService.showInfo('Espere', 'Descarga en proceso');
  }
}
