import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'input',
})
export class InputPipe implements PipeTransform {

  transform(list: any[], arg: string): any[] {
    return list.filter(
      (item) =>
        item.label.toLocaleLowerCase().includes(arg.toLocaleLowerCase()) ||
        item.code.toLocaleLowerCase().includes(arg.toLocaleLowerCase()),
    );
  }
}
