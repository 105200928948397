import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from 'app/config';
import { IBusiness, ICfs } from '../models/business.model';
import { OptionQuery } from '../models/option-query.model';
import { Business } from '../enums/business.enum';

const BASE_URL = `${config.api.url}${config.business.mainEndpoint}`;
const BASE_URL_B_I = `${config.api.url}${config.businessInstance.mainEndpoint}`;
const BASE_URL_MARK = `${config.api.url}${config.uniqueMark.uniqueMarkImportLimit}`;
const BASE_URL_UNIQUE_MARK = `${config.api.url}${config.uniqueMark.mainEndpoint}`;
const BASE_URL_LOTS_UNIQUE_MARK = `${config.api.url}${config.lotsUniqueMark.mainEndpoint}`;

@Injectable({
  providedIn: 'root',
})
export class BusinessService {
  constructor(private http: HttpClient) {}

  private _business: any;

  get business() {
    return { ...this._business };
  }

  public delete(id: string) {
    return this.http.delete(`${BASE_URL}${id}`);
  }

  public getAll(optionQuery: OptionQuery) {
    let query = `${BASE_URL}?`;
    if (optionQuery.offset && optionQuery.limit) {
      query += `offset=${optionQuery.offset}&limit=${optionQuery.limit}&`;
    }
    if (optionQuery.sortDirection && optionQuery.sortField)
      query += `sortField=${optionQuery.sortField}&sortDirection=${optionQuery.sortDirection}&`;
    if (optionQuery.filterField && optionQuery.filterValue)
      query += `filterField=${optionQuery.filterField}&filterValue=${optionQuery.filterValue}&`;
    if (optionQuery.boolField && optionQuery.boolValue !== undefined)
      query += `boolField=${optionQuery.boolField}&boolValue=${optionQuery.boolValue}`;
    return this.http.get(query);
  }

  public getAllNoPaginate() {
    return this.http.get(`${BASE_URL}`);
  }

  public getById(id: string) {
    const response = this.http.get(`${BASE_URL}${id}?relations=${Business.RELATIONS_SHOPPING_EXPERIENCE}`);
    response.subscribe(res => {
      this._business = res;
    });
    return response;
  }
  public create(data: IBusiness) {
    return this.http.post(`${BASE_URL}`, data);
  }
  public asociateInstance(data: any) {
    return this.http.post(`${BASE_URL_B_I}`, data);
  }

  public updateStatusInstance(id: any, datos: any) {
    return this.http.put(`${BASE_URL_B_I}/${id}`, datos);
  }

  public update(data: IBusiness, id: string) {
    return this.http.put(`${BASE_URL}${id}`, data);
  }

  public getAllBusiness(optionQuery: OptionQuery) {
    let query = `${BASE_URL}?`;
    if (optionQuery.offset && optionQuery.limit) {
      query += `offset=${optionQuery.offset}&limit=${optionQuery.limit}&`;
    }
    if (optionQuery.sortDirection && optionQuery.sortField)
      query += `sortField=${optionQuery.sortField}&sortDirection=${optionQuery.sortDirection}&`;
    if (optionQuery.filterField && optionQuery.filterValue)
      query += `filterField=${optionQuery.filterField}&filterValue=${optionQuery.filterValue}&`;
    if (optionQuery.boolField && optionQuery.boolValue !== undefined)
      query += `boolField=${optionQuery.boolField}&boolValue=${optionQuery.boolValue}`;
    return this.http.get(query);
  }

  public cfsActivate(data: ICfs) {
    return this.http.post(`${BASE_URL_MARK}`, data);
  }

  public cfsResumen(id: string) {
    return this.http.get(`${BASE_URL_UNIQUE_MARK}${config.uniqueMark.resume}${id}`);
  }
  public cfsLotsResumen(id: string) {
    return this.http.get(`${BASE_URL_LOTS_UNIQUE_MARK}${config.lotsUniqueMark.resume}${id}`);
  }

  public cfsUniqueMarkLimit(id: string) {
    return this.http.get(`${BASE_URL_MARK}${config.uniqueMark.resume}${id}`);
  }
}
