<p-dialog [contentStyle]="{'overflow-y': 'auto', 'overflow-x': 'hidden'}" header="Productos"
    [(visible)]="visibleProduct" [modal]="true" [style]="{width: '40vw'}" [closable]="false" [baseZIndex]="10000">
    <div class="container">
        <p-dataView #dv [value]="listProductResume" layout="list">
            <ng-template pTemplate="header" class="header-data">
                <div>
                    <span class="p-mb-2 p-mb-md-0">
                        <input type="text" pInputText placeholder="Buscar producto"
                            [(ngModel)]="optionQuery.filterValue"
                            (ngModelChange)="this.productSearchUpdate.next($event)" class="p-inputtext p-component"
                            [style]="{width: '100%'}">
                    </span>
                </div>
            </ng-template>
            <ng-template let-item pTemplate="listItem">
                <div class="p-col-12">
                    <div class="item-list-item list">
                        <div class="item-list-detail">
                            <div class="p-grid left-margin-list-content">
                                <div class="p-col-6">
                                    <div class="header-title">Producto</div>
                                    <div class="item-list-title">
                                        {{item?.description}}
                                    </div>
                                </div>
                                <div class="p-col-6">
                                    <div class="header-title">ICE- SKU</div>
                                    <div class="item-list-title">{{item?.icesku || item?.iceSku}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="item-list-action">
                            <div class="p-col">
                                <button type="button" nbButton size="small" status="info" (click)="productEmit(item)"
                                    class="p-button p-mr-2">
                                    Seleccionar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-dataView>
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="close()" label="cancelar" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>